// @flow
import type { SubscriptionLengthType, PromoType } from '../flowTypes'

export const SUBSCRIPTION_TYPES = Object.freeze({
  ANNUAL: 'annual',
  MONTHLY: 'monthly',
  SIX_MONTHS: 'six_months'
})

// If a subType is not specified, annual is assumed
export const PROMO_KEYS: PromoType[] = [
  { reg: /^wordlove$/i, promo: 'onemonthfreetrial', event: 'Bey Day Dance Class' },
  { reg: /^tia2019$/i, promo: 'onemonthfreetrial', event: 'Tia Clinic Dipsea Panel on July 24, 2019' },
  { reg: /^androidbeta$/i, promo: 'onemonthfreetrial', event: 'Android Beta Test' },
  { reg: /^oneweekfreetrial$/i, promo: 'oneweekfreetrial' },
  { reg: /^hunk-quiz-week-free$/i, promo: 'oneweekfreetrial' },
  { reg: /^trope-quiz-week-free$/i, promo: 'oneweekfreetrial' },
  { reg: /^twoweeksfreetrial$/i, promo: 'twoweeksfreetrial' },
  { reg: /^onemonthfreetrial$/i, promo: 'onemonthfreetrial' },
  { reg: /^twomonthfreetrial$/i, promo: 'twomonthfreetrial' },
  { reg: /^legacyannualprice$/i, promo: '4799-price-7-day-trial' },
  { reg: /^DS\d+$/i, promo: '25percentoff+oneweekfreetrial', event: 'Therapist outreach' },
  { reg: /^goodvibes|good\svibes$/i, promo: '25percentoff+oneweekfreetrial', event: 'Goodvibes' },
  { reg: /^CSA/i, promo: 'onemonthfreetrial', event: 'Churn Survey' }, // CHURN SURVEY: New stories and sessions were released more frequently
  { reg: /^CSB/i, promo: 'onemonthfreetrial', event: 'Churn Survey' }, // CHURN SURVEY: Stories and sessions better suited my interests
  { reg: /^CSC/i, promo: 'onemonthfreetrial', event: 'Churn Survey' }, // CHURN SURVEY: Liked the stories and sessions more
  { reg: /^CSD/i, promo: 'onemonthfreetrial', event: 'Churn Survey' }, // CHURN SURVEY: Listened more frequently
  { reg: /^CSE/i, promo: '25percentoff', event: 'Churn Survey' }, // CHURN SURVEY: Price was lower
  { reg: /^CSF/i, promo: 'onemonthfreetrial', event: 'Churn Survey' }, // CHURN SURVEY: App was better
  { reg: /^prudence$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: prudence' },
  { reg: /^girls$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: girls' },
  { reg: /^ellie$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: ellie' },
  { reg: /^teencreeps$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: teencreeps' },
  { reg: /^wonder$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: wonder' },
  { reg: /^worst$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: worst' },
  { reg: /^bestfriends$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: bestfriends' },
  { reg: /^blame$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: blame' },
  { reg: /^alison$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: alison' },
  { reg: /^glowgirl$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: glowgirl' },
  { reg: /^yogagirldaily$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: yogagirldaily' },
  { reg: /^shade$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: shade' },
  { reg: /^tak$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: tak' },
  { reg: /^cocktales$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: cocktales' },
  { reg: /^gop$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: gop' },
  { reg: /^mother$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: mother' },
  { reg: /^welcome$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: welcome' },
  { reg: /^angela$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: angela' },
  { reg: /^secretlives$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: secretlives', welcomeBannerText: 'Welcome The Secret Room listeners!' },
  { reg: /^tristan$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: tristan' },
  { reg: /^dixie$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: dixie' },
  { reg: /^holly$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: holly' },
  { reg: /^mom$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: mom' },
  { reg: /^sunny$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: sunny' },
  { reg: /^shameless$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: shameless' },
  { reg: /^multi$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: multi' },
  { reg: /^sands$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: sands' },
  { reg: /^private$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: private' },
  { reg: /^natch$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: natch' },
  { reg: /^ihu$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: ihu' },
  { reg: /^btch$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: btch' },
  { reg: /^whoregasmic$/i, promo: 'onemonthfreetrial', event: 'IG partnership' },
  { reg: /^thewing$/i, promo: '25percentoff', event: 'the wing partnership' },
  { reg: /^dame$/i, promo: '25percentoff+onemonthfreetrial', event: 'dame partnership' },
  { reg: /^awearxwmaa$/i, promo: 'onemonthfreetrial', event: 'awearxwmaa partnership' },
  { reg: /^mrsfletcher$/i, promo: 'onemonthfreetrial', event: 'Mrs.Fletcher event Oct 2019' },
  { reg: /^selfcare$/i, promo: '25percentoff+oneweekfreetrial', event: 'selfcare partnership' },
  { reg: /^hottakes$/i, promo: 'onemonthfreetrial', event: 'hottakes partnership' },
  { reg: /^bedside$/i, promo: 'onemonthfreetrial', event: 'bedside partnership' },
  { reg: /^mollyshephard$/i, promo: 'onemonthfreetrial', event: 'Microinfluencer Box' },
  { reg: /^zoiladarton$/i, promo: 'onemonthfreetrial', event: 'Microinfluencer Box' },
  { reg: /^melaniesantos$/i, promo: 'onemonthfreetrial', event: 'Microinfluencer Box' },
  { reg: /^danibeinstein$/i, promo: 'onemonthfreetrial', event: 'Microinfluencer Box' },
  { reg: /^eliserpeterson$/i, promo: 'onemonthfreetrial', event: 'Microinfluencer Box' },
  { reg: /^meccajw$/i, promo: 'onemonthfreetrial', event: 'Microinfluencer Box' },
  { reg: /^elaineinthecity$/i, promo: 'onemonthfreetrial', event: 'Microinfluencer Box' },
  { reg: /^artofloving$/i, promo: 'onemonthfreetrial', event: 'Microinfluencer Box' },
  { reg: /^christinareaddd$/i, promo: 'onemonthfreetrial', event: 'Microinfluencer Box' },
  { reg: /^keamonef$/i, promo: 'onemonthfreetrial', event: 'Microinfluencer Box' },
  { reg: /^natalieispoetry$/i, promo: 'onemonthfreetrial', event: 'Microinfluencer Box' },
  { reg: /^iischristy$/i, promo: 'onemonthfreetrial', event: 'Microinfluencer Box' },
  { reg: /^zoieloves$/i, promo: 'onemonthfreetrial', event: 'Microinfluencer Box' },
  { reg: /^leneasims$/i, promo: 'onemonthfreetrial', event: 'Microinfluencer Box' },
  { reg: /^kaitlinprest$/i, promo: 'onemonthfreetrial', event: 'Microinfluencer Box' },
  { reg: /^cat\.lantigua$/i, promo: 'onemonthfreetrial', event: 'Microinfluencer Box' },
  { reg: /^jazzloon$/i, promo: 'onemonthfreetrial', event: 'Microinfluencer Box' },
  { reg: /^starlingirving$/i, promo: 'onemonthfreetrial', event: 'Microinfluencer Box' },
  { reg: /^twochairs$/i, promo: 'onemonthfreetrial', event: 'twochairs partnership' },
  { reg: /^wasc$/i, promo: '25percentoff', event: 'Therapists partnership' },
  { reg: /^amandakater$/i, promo: 'onemonthfreetrial', event: 'Influencers partnership' },
  { reg: /^alexandrabanessa$/i, promo: 'onemonthfreetrial', event: 'Influencers partnership' },
  { reg: /^xxrhiannajones$/i, promo: 'onemonthfreetrial', event: 'Influencers partnership' },
  { reg: /^lalalopez$/i, promo: 'onemonthfreetrial', event: 'Influencers partnership' },
  { reg: /^millanasnow$/i, promo: 'onemonthfreetrial', event: 'Influencers partnership' },
  { reg: /^cmccrearyyoga$/i, promo: 'onemonthfreetrial', event: 'Influencers partnership' },
  { reg: /^fab50s$/i, promo: 'onemonthfreetrial', event: 'Customer partnership' },
  { reg: /^unwell$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: unwell' },
  { reg: /^sup$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: sup' },
  { reg: /^natcp$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: natcp' },
  { reg: /^single$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: single' },
  { reg: /^waves$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: waves' },
  { reg: /^momanddad$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: momanddad' },
  { reg: /^ologies$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: ologies' },
  { reg: /^whoreible$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: whoreible' },
  { reg: /^pride$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: pride' },
  { reg: /^askwomen$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: askwomen' },
  { reg: /^mm$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: mm' },
  { reg: /^geethanks$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: geethanks' },
  { reg: /^hollywood$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: hollywood' },
  { reg: /^lezhangout$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: lezhangout' },
  { reg: /^viall$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: viall' },
  { reg: /^straight$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: straight' },
  { reg: /^gabbie$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: gabbie' },
  { reg: /^nochaser$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: nochaser' },
  { reg: /^violet$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: violet' },
  { reg: /^secret$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: secret', welcomeBannerText: 'Welcome The Secret Room listeners!' },
  { reg: /^amr$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: amr' },
  { reg: /^ido$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: ido' },
  { reg: /^wehear$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: wehear' },
  { reg: /^hoxton$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: hoxton' },
  { reg: /^webapp$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: webapp' },
  { reg: /^erinunleashes$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: erinunleashes' },
  { reg: /^uqora$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: uqora' },
  { reg: /^hellenorosevelt$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: hellenorosevelt' },
  { reg: /^sexyhour$/i, promo: 'onemonthfreetrial', event: 'sexyhour partnership' },
  { reg: /^backstage$/i, promo: 'onemonthfreetrial', event: 'backstage partnership' },
  { reg: /^askasub$/i, promo: 'onemonthfreetrial', event: 'askasub partnership' },
  { reg: /^misscoryb$/i, promo: 'onemonthfreetrial', event: 'misscoryb partnership' },
  { reg: /^jejoue$/i, promo: 'onemonthfreetrial', event: 'jejoue partnership' },
  { reg: /^facebook$/i, promo: 'onemonthfreetrial', event: 'facebook' },
  { reg: /^unbound$/i, promo: 'onemonthfreetrial', event: 'unbound partnership' },
  { reg: /^thankyouhero$/i, promo: 'onemonthfreetrial', event: 'thankyouhero partnership' },
  { reg: /^femtech$/i, promo: 'onemonthfreetrial', event: 'femtech partnership' },
  { reg: /^divebackin$/i, promo: 'twomonthfreetrial+oneyear20dollars1', event: 'winback: divebackin' },
  { reg: /^sexysummer$/i, promo: 'onedollarpermonthforthreemonths1', event: 'winback: sexysummer', header: 'Get 3 months of Dipsea for just $1/month', subHeader: 'Dipsea’s sexy summer is here. Get unlimited access to hundreds of sexy audio stories and wellness sessions. Cancel anytime.', subType: SUBSCRIPTION_TYPES.MONTHLY },
  { reg: /^jamieleclaire$/i, promo: 'onemonthfreetrial', event: 'jamieleclaire partnership' },
  { reg: /^nerylwalker$/i, promo: 'onemonthfreetrial', event: 'nerylwalker partnership' },
  { reg: /^aureliadurand$/i, promo: 'onemonthfreetrial', event: 'aureliadurand partnership' },
  { reg: /^clemencegouy$/i, promo: 'onemonthfreetrial', event: 'clemencegouy partnership' },
  { reg: /^hollystapleton$/i, promo: 'onemonthfreetrial', event: 'hollystapleton partnership' },
  { reg: /^ambervittoria$/i, promo: 'onemonthfreetrial', event: 'ambervittoria partnership' },
  { reg: /^solacilike$/i, promo: 'onemonthfreetrial', event: 'solacilike partnership' },
  { reg: /^shrimpteeth$/i, promo: 'onemonthfreetrial', event: 'shrimpteeth partnership' },
  { reg: /^speakingofsex$/i, promo: 'onemonthfreetrial', event: 'speakingofsex partnership' },
  { reg: /^rubyrare$/i, promo: 'onemonthfreetrial', event: 'rubyrare partnership' },
  { reg: /^checheluna$/i, promo: 'onemonthfreetrial', event: 'checheluna partnership' },
  { reg: /^gabalexa$/i, promo: 'onemonthfreetrial', event: 'gabalexa partnership' },
  { reg: /^pussywitch$/i, promo: 'onemonthfreetrial', event: 'pussywitch partnership' },
  { reg: /^talesoflara$/i, promo: 'onemonthfreetrial', event: 'talesoflara partnership' },
  { reg: /^sexelducation$/i, promo: 'onemonthfreetrial', event: 'sexelducation partnership' },
  { reg: /^gabriellekassel$/i, promo: 'onemonthfreetrial', event: 'gabriellekassel partnership' },
  { reg: /^wwwmm$/i, promo: 'onemonthfreetrial', event: 'wwwmm partnership' },
  { reg: /^corinnekai$/i, promo: 'onemonthfreetrial', event: 'corinnekai partnership' },
  { reg: /^kimraluna$/i, promo: 'onemonthfreetrial', event: 'kimraluna partnership' },
  { reg: /^thesexdoctor$/i, promo: 'onemonthfreetrial', event: 'thesexdoctor partnership' },
  { reg: /^drtina$/i, promo: 'onemonthfreetrial', event: 'drtina partnership' },
  { reg: /^lovewithjanel$/i, promo: 'onemonthfreetrial', event: 'lovewithjanel partnership' },
  { reg: /^misskatt$/i, promo: 'onemonthfreetrial', event: 'misskatt partnership' },
  { reg: /^medinah$/i, promo: 'onemonthfreetrial', event: 'medinah partnership' },
  { reg: /^josephinerais$/i, promo: 'onemonthfreetrial', event: 'josephinerais partnership' },
  { reg: /^vanessalee$/i, promo: 'onemonthfreetrial', event: 'vanessalee partnership' },
  { reg: /^amyvagabond$/i, promo: 'onemonthfreetrial', event: 'amyvagabond partnership' },
  { reg: /^looloolentil$/i, promo: 'onemonthfreetrial', event: 'looloolentil partnership' },
  { reg: /^stefanieelsperman$/i, promo: 'onemonthfreetrial', event: 'stefanieelsperman partnership' },
  { reg: /^evolveandbloom$/i, promo: 'onemonthfreetrial', event: 'evolveandbloom partnership' },
  { reg: /^datewithbritt$/i, promo: 'onemonthfreetrial', event: 'datewithbritt partnership' },
  { reg: /^keramone$/i, promo: 'onemonthfreetrial', event: 'keramone partnership' },
  { reg: /^alexsundstrom$/i, promo: 'onemonthfreetrial', event: 'alexsundstrom partnership' },
  { reg: /^dirtydiana$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: dirtydiana' },
  { reg: /^diana$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: diana' },
  { reg: /^referafriend$/i, promo: 'onemonthfreetrial' },
  { reg: /^pskaguya$/i, promo: 'onemonthfreetrial', event: 'partnership pskaguya' },
  { reg: /^queersextherapy$/i, promo: 'onemonthfreetrial', event: 'partnership queersextherapy' },
  { reg: /^alixfox$/i, promo: 'onemonthfreetrial', event: 'partnership alixfox' },
  { reg: /^libby$/i, promo: 'onemonthfreetrial', event: 'partnership libby' },
  { reg: /^queerhotsauce$/i, promo: 'onemonthfreetrial', event: 'partnership queerhotsauce' },
  { reg: /^saraclark$/i, promo: 'onemonthfreetrial', event: 'partnership saraclark' },
  { reg: /^ashaesundara$/i, promo: 'onemonthfreetrial', event: 'partnership ashaesundara' },
  { reg: /^heylauraheyyy$/i, promo: 'onemonthfreetrial', event: 'partnership heylauraheyyy' },
  { reg: /^flexmami$/i, promo: 'onemonthfreetrial', event: 'partnership flexmami' },
  { reg: /^carakovacs$/i, promo: 'onemonthfreetrial', event: 'partnership carakovacs' },
  { reg: /^sexwithashley$/i, promo: 'onemonthfreetrial', event: 'partnership sexwithashley' },
  { reg: /^thesensiblesexpert$/i, promo: 'onemonthfreetrial', event: 'partnership thesensiblesexpert' },
  { reg: /^feministsexed$/i, promo: 'onemonthfreetrial', event: 'partnership feministsexed' },
  { reg: /^evabloom$/i, promo: 'onemonthfreetrial', event: 'partnership evabloom' },
  { reg: /^sexnerdsandra$/i, promo: 'onemonthfreetrial', event: 'partnership sexnerdsandra' },
  { reg: /^shelbysellslove$/i, promo: 'onemonthfreetrial', event: 'partnership shelbysellslove' },
  { reg: /^charynpfeuffer$/i, promo: 'onemonthfreetrial', event: 'partnership charynpfeuffer' },
  { reg: /^sexplanations$/i, promo: 'onemonthfreetrial', event: 'partnership sexplanations' },
  { reg: /^hannahwitton$/i, promo: 'onemonthfreetrial', event: 'partnership hannahwitton' },
  { reg: /^comecurious$/i, promo: 'onemonthfreetrial', event: 'partnership comecurious' },
  { reg: /^tinahorn$/i, promo: 'onemonthfreetrial', event: 'partnership tinahorn' },
  { reg: /^shadeenfrancis$/i, promo: 'onemonthfreetrial', event: 'partnership shadeenfrancis' },
  { reg: /^jimanekia$/i, promo: 'onemonthfreetrial', event: 'partnership jimanekia' },
  { reg: /^girlonthenet$/i, promo: 'onemonthfreetrial', event: 'partnership girlonthenet' },
  { reg: /^hoodratfeminist$/i, promo: 'onemonthfreetrial', event: 'partnership hoodratfeminist' },
  { reg: /^alaynajoy$/i, promo: 'onemonthfreetrial', event: 'partnership alaynajoy' },
  { reg: /^avajohanna$/i, promo: 'onemonthfreetrial', event: 'partnership avajohanna' },
  { reg: /^omandthecity$/i, promo: 'onemonthfreetrial', event: 'partnership omandthecity' },
  { reg: /^jamilareddy$/i, promo: 'onemonthfreetrial', event: 'partnership jamilareddy' },
  { reg: /^bykwest$/i, promo: 'onemonthfreetrial', event: 'partnership bykwest' },
  { reg: /^pamcosta$/i, promo: 'onemonthfreetrial', event: 'partnership pamcosta' },
  { reg: /^wings$/i, promo: 'onemonthfreetrial', event: 'partnership wings' },
  { reg: /^mygirlwellness$/i, promo: 'onemonthfreetrial', event: 'partnership mygirlwellness' },
  { reg: /^outinthebay$/i, promo: 'onemonthfreetrial', event: 'partnership outinthebay' },
  { reg: /^bumble$/i, promo: 'onemonthfreetrial', event: 'partnership bumble' },
  { reg: /^goodmoms$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: goodmoms' },
  { reg: /^howcum$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: howcum' },
  { reg: /^honeymoon$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: honeymoon' },
  { reg: /^hysteria$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: hysteria' },
  { reg: /^papaya$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: papaya' },
  { reg: /^acme$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: acme' },
  { reg: /^theta$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: theta' },
  { reg: /^harlem$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: harlem' },
  { reg: /^greater$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: greater' },
  { reg: /^spacejunk$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: spacejunk' },
  { reg: /^marsfall$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: marsfall' },
  { reg: /^alba$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: alba' },
  { reg: /^amelia$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: amelia' },
  { reg: /^geniuses$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: geniuses' },
  { reg: /^fanti$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: fanti' },
  { reg: /^zyxx$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: zyxx' },
  { reg: /^badmother$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: badmother' },
  { reg: /^spirits$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: spirits' },
  { reg: /^arden$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: arden' },
  { reg: /^bern$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: bern' },
  { reg: /^trashybooks$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: trashybooks' },
  { reg: /^multi$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: multi' },
  { reg: /^heart$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: heart' },
  { reg: /^rcp$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: rcp' },
  { reg: /^outward$/i, promo: 'onemonthfreetrial', event: 'podcast campaign: outward' },
  { reg: /^prohoe$/i, promo: 'onemonthfreetrial', event: 'partnership prohoe' },
  { reg: /^gge$/i, promo: 'onemonthfreetrial', event: 'podcast gge' },
  { reg: /^down$/i, promo: 'onemonthfreetrial', event: 'podcast down' },
  { reg: /^carlotta$/i, promo: 'onemonthfreetrial', event: 'podcast carlotta' },
  { reg: /^civilized$/i, promo: 'onemonthfreetrial', event: 'podcast civilized' },
  { reg: /^loveletters$/i, promo: 'onemonthfreetrial', event: 'podcast loveletters' },
  { reg: /^sheratesdogs$/i, promo: 'onemonthfreetrial', event: 'podcast sheratesdogs' },
  { reg: /^winethirty$/i, promo: 'onemonthfreetrial', event: 'podcast winethirty' },
  { reg: /^bachreport$/i, promo: 'onemonthfreetrial', event: 'podcast bachreport' },
  { reg: /^craycray$/i, promo: 'onemonthfreetrial', event: 'podcast craycray' },
  { reg: /^sesh$/i, promo: 'onemonthfreetrial', event: 'podcast sesh' },
  { reg: /^odh$/i, promo: 'onemonthfreetrial', event: 'podcast odh' },
  { reg: /^unlocknow$/i, promo: '25percentoff+oneweekfreetrial', event: 'unlocknow campaign' },
  { reg: /^raquelsavage$/i, promo: 'onemonthfreetrial', event: 'partnership raquelsavage' },
  { reg: /^estrid$/i, promo: 'onemonthfreetrial', event: 'partnership estrid' },
  { reg: /^modernfertility$/i, promo: 'threemonthsfreetrial', event: 'partnership modernfertility' },
  { reg: /^iris$/i, promo: 'onemonthfreetrial', event: 'partnership iris' },
  { reg: /^fps$/i, promo: 'onemonthfreetrial', event: 'partnership fps' },
  { reg: /^thisisgood$/i, promo: 'onemonthfreetrial', event: 'partnership thisisgood' },
  { reg: /^best$/i, promo: 'onemonthfreetrial', event: 'partnership best' },
  { reg: /^braincandy$/i, promo: 'onemonthfreetrial', event: 'podcast braincandy' },
  { reg: /^kya$/i, promo: 'onemonthfreetrial', event: 'podcast kya' },
  { reg: /^coco$/i, promo: 'onemonthfreetrial', event: 'podcast coco' },
  { reg: /^taylor$/i, promo: 'onemonthfreetrial', event: 'podcast taylor' },
  { reg: /^getit$/i, promo: 'onemonthfreetrial', event: 'podcast getit' },
  { reg: /^ladygang$/i, promo: 'onemonthfreetrial', event: 'podcast ladygang' },
  { reg: /^wad$/i, promo: 'onemonthfreetrial', event: 'podcast wad' },
  { reg: /^fantasy$/i, promo: 'onemonthfreetrial', event: 'podcast fantasy' },
  { reg: /^almost30$/i, promo: 'onemonthfreetrial', event: 'podcast almost30' },
  { reg: /^helpless$/i, promo: 'onemonthfreetrial', event: 'podcast helpless' },
  { reg: /^seektreatment$/i, promo: 'onemonthfreetrial', event: 'podcast seektreatment' },
  { reg: /^poog$/i, promo: 'onemonthfreetrial', event: 'podcast poog' },
  { reg: /^romance$/i, promo: 'onemonthfreetrial', event: 'podcast romance' },
  { reg: /^staystrange$/i, promo: 'onemonthfreetrial', event: 'podcast staystrange' },
  { reg: /^hunter$/i, promo: 'onemonthfreetrial', event: 'podcast hunter' },
  { reg: /^cyg$/i, promo: 'onemonthfreetrial', event: 'podcast cyg' },
  { reg: /^mindlove$/i, promo: 'onemonthfreetrial', event: 'podcast mindlove' },
  { reg: /^chatty$/i, promo: 'onemonthfreetrial', event: 'podcast chatty' },
  { reg: /^lovewithjanel$/i, promo: 'onemonthfreetrial', event: 'partnership lovewithjanel' },
  { reg: /^samara$/i, promo: 'onemonthfreetrial', event: 'partnership samara' },
  { reg: /^keramone$/i, promo: 'onemonthfreetrial', event: 'partnership keramone' },
  { reg: /^bathbabe$/i, promo: 'onemonthfreetrial', event: 'partnership bathbabe' },
  { reg: /^macy$/i, promo: 'onemonthfreetrial', event: 'partnership macy' },
  { reg: /^sydandolivia$/i, promo: 'onemonthfreetrial', event: 'partnership sydandolivia' },
  { reg: /^jocelyn$/i, promo: 'onemonthfreetrial', event: 'partnership jocelyn' },
  { reg: /^widya$/i, promo: 'onemonthfreetrial', event: 'partnership widya' },
  { reg: /^tinymedicine$/i, promo: 'onemonthfreetrial', event: 'partnership tinymedicine' },
  { reg: /^dejafoxx$/i, promo: 'onemonthfreetrial', event: 'partnership dejafoxx' },
  { reg: /^hiramaja$/i, promo: 'onemonthfreetrial', event: 'partnership hiramaja' },
  { reg: /^kittieharloe$/i, promo: 'onemonthfreetrial', event: 'partnership kittieharloe' },
  { reg: /^tgog$/i, promo: 'onemonthfreetrial', event: 'podcast tgog' },
  { reg: /^cyg$/i, promo: 'onemonthfreetrial', event: 'podcast cyg' },
  { reg: /^blonde$/i, promo: 'onemonthfreetrial', event: 'podcast blonde' },
  { reg: /^bigkid$/i, promo: 'onemonthfreetrial', event: 'podcast bigkid' },
  { reg: /^friend$/i, promo: 'onemonthfreetrial', event: 'podcast friend' },
  { reg: /^athingortwo$/i, promo: 'onemonthfreetrial', event: 'podcast athingortwo' },
  { reg: /^whatwesaid$/i, promo: 'onemonthfreetrial', event: 'podcast whatwesaid' },
  { reg: /^mnm$/i, promo: 'onemonthfreetrial', event: 'podcast mnm' },
  { reg: /^pantsuit$/i, promo: 'onemonthfreetrial', event: 'podcast pantsuit' },
  { reg: /^nochaser$/i, promo: 'onemonthfreetrial', event: 'podcast nochaser' },
  { reg: /^opp$/i, promo: 'onemonthfreetrial', event: 'podcast opp' },
  { reg: /^glasses$/i, promo: 'onemonthfreetrial', event: 'podcast glasses' },
  { reg: /^unladylike$/i, promo: 'onemonthfreetrial', event: 'podcast unladylike' },
  { reg: /^lovelife$/i, promo: 'onemonthfreetrial', event: 'podcast lovelife' },
  { reg: /^flashforward$/i, promo: 'onemonthfreetrial', event: 'podcast flashforward' },
  { reg: /^veronica$/i, promo: 'onemonthfreetrial', event: 'podcast veronica' },
  { reg: /^rohini$/i, promo: 'onemonthfreetrial', event: 'podcast rohini' },
  { reg: /^hobbyhunter$/i, promo: 'onemonthfreetrial', event: 'podcast hobbyhunter' },
  { reg: /^mood$/i, promo: 'onemonthfreetrial', event: 'podcast mood' },
  { reg: /^planbri$/i, promo: 'onemonthfreetrial', event: 'podcast planbri' },
  { reg: /^criminalbroads$/i, promo: 'onemonthfreetrial', event: 'podcast criminalbroads' },
  { reg: /^crimesalad$/i, promo: 'onemonthfreetrial', event: 'podcast crimesalad' },
  { reg: /^creepy$/i, promo: 'onemonthfreetrial', event: 'podcast creepy' },
  { reg: /^gals$/i, promo: 'onemonthfreetrial', event: 'podcast gals' },
  { reg: /^happiness$/i, promo: 'onemonthfreetrial', event: 'podcast happiness' },
  { reg: /^truth$/i, promo: 'onemonthfreetrial', event: 'podcast truth' },
  { reg: /^twinning$/i, promo: 'onemonthfreetrial', event: 'podcast twinning' },
  { reg: /^couch$/i, promo: 'onemonthfreetrial', event: 'podcast couch' },
  { reg: /^qlc$/i, promo: 'onemonthfreetrial', event: 'podcast qlc' },
  { reg: /^weeklydose$/i, promo: 'onemonthfreetrial', event: 'podcast weeklydose' },
  { reg: /^getreal$/i, promo: 'onemonthfreetrial', event: 'podcast getreal' },
  { reg: /^90210mg$/i, promo: 'onemonthfreetrial', event: 'podcast 90210mg' },
  { reg: /^becca$/i, promo: 'onemonthfreetrial', event: 'podcast becca' },
  { reg: /^wells$/i, promo: 'onemonthfreetrial', event: 'podcast wells' },
  { reg: /^alimac$/i, promo: 'onemonthfreetrial', event: 'podcast alimac' },
  { reg: /^pleasure$/i, promo: 'onemonthfreetrial', event: 'podcast pleasure' },
  { reg: /^florence$/i, promo: 'onemonthfreetrial', event: 'paid florence' },
  { reg: /^rachasmr$/i, promo: 'onemonthfreetrial', event: 'paid rachasmr' },
  { reg: /^partylikemaddie$/i, promo: 'onemonthfreetrial', event: 'paid partylikemaddie', welcomeBannerText: 'Welcome Maddie Dragsbaek viewers!' },
  { reg: /^paigeandholly$/i, promo: 'onemonthfreetrial', event: 'paid paigeandholly' },
  { reg: /^alana$/i, promo: 'onemonthfreetrial', event: 'paid alana' },
  { reg: /^high$/i, promo: 'onemonthfreetrial', event: 'paid high' },
  { reg: /^depresh$/i, promo: 'onemonthfreetrial', event: 'paid depresh' },
  { reg: /^minority$/i, promo: 'onemonthfreetrial', event: 'paid minority' },
  { reg: /^jvclub$/i, promo: 'onemonthfreetrial', event: 'paid jvclub' },
  { reg: /^tiny$/i, promo: 'onemonthfreetrial', event: 'paid tiny' },
  { reg: /^dani$/i, promo: 'onemonthfreetrial', event: 'paid dani' },
  { reg: /^ginger$/i, promo: 'onemonthfreetrial', event: 'paid ginger' },
  { reg: /^joanna$/i, promo: 'onemonthfreetrial', event: 'paid joanna' },
  { reg: /^gigglysquad$/i, promo: 'onemonthfreetrial', event: 'paid gigglysquad' },
  { reg: /^angrytherapist$/i, promo: 'onemonthfreetrial', event: 'paid angrytherapist' },
  { reg: /^sup$/i, promo: 'onemonthfreetrial', event: 'paid sup' },
  { reg: /^alarmist$/i, promo: 'onemonthfreetrial', event: 'paid alarmist' },
  { reg: /^fatmascara$/i, promo: 'onemonthfreetrial', event: 'paid fatmascara' },
  { reg: /^challenged$/i, promo: 'onemonthfreetrial', event: 'paid challenged' },
  { reg: /^webcrawlers$/i, promo: 'onemonthfreetrial', event: 'paid webcrawlers' },
  { reg: /^natchbeaut$/i, promo: 'onemonthfreetrial', event: 'paid natchbeaut' },
  { reg: /^time$/i, promo: 'onemonthfreetrial', event: 'paid time' },
  { reg: /^chanel$/i, promo: 'onemonthfreetrial', event: 'paid chanel' },
  { reg: /^trashy$/i, promo: 'onemonthfreetrial', event: 'paid trashy' },
  { reg: /^badexamples$/i, promo: 'onemonthfreetrial', event: 'paid badexamples' },
  { reg: /^francine$/i, promo: 'onemonthfreetrial', event: 'paid francine' },
  { reg: /^dysfunction$/i, promo: 'onemonthfreetrial', event: 'paid dysfunction' },
  { reg: /^girlwhy$/i, promo: 'onemonthfreetrial', event: 'paid girlwhy' },
  { reg: /^goodgirls$/i, promo: 'onemonthfreetrial', event: 'paid goodgirls' },
  { reg: /^oc$/i, promo: 'onemonthfreetrial', event: 'paid oc' },
  { reg: /^mortified$/i, promo: 'onemonthfreetrial', event: 'paid mortified' },
  { reg: /^outward$/i, promo: 'onemonthfreetrial', event: 'paid outward' },
  { reg: /^waves$/i, promo: 'onemonthfreetrial', event: 'paid waves' },
  { reg: /^rotten$/i, promo: 'onemonthfreetrial', event: 'paid rotten' },
  { reg: /^snooki$/i, promo: 'onemonthfreetrial', event: 'paid snooki' },
  { reg: /^onlyfriends$/i, promo: 'onemonthfreetrial', event: 'paid onlyfriends' },
  { reg: /^womenwhotravel$/i, promo: 'onemonthfreetrial', event: 'paid womenwhotravel' },
  { reg: /^cbc$/i, promo: 'onemonthfreetrial', event: 'paid cbc' },
  { reg: /^who$/i, promo: 'onemonthfreetrial', event: 'paid who' },
  { reg: /^sofia$/i, promo: 'onemonthfreetrial', event: 'paid sofia' },
  { reg: /^foster$/i, promo: 'onemonthfreetrial', event: 'paid foster' },
  { reg: /^startripper$/i, promo: 'onemonthfreetrial', event: 'paid startripper' },
  { reg: /^rawbeauty$/i, promo: 'onemonthfreetrial', event: 'paid rawbeauty' },
  { reg: /^kickass$/i, promo: 'onemonthfreetrial', event: 'paid kickass' },
  { reg: /^mm$/i, promo: 'onemonthfreetrial', event: 'paid mm' },
  { reg: /^rose$/i, promo: 'onemonthfreetrial', event: 'paid rose' },
  { reg: /^sis$/i, promo: 'onemonthfreetrial', event: 'paid sis' },
  { reg: /^shrink$/i, promo: 'onemonthfreetrial', event: 'paid shrink' },
  { reg: /^devinlytle$/i, promo: 'onemonthfreetrial', event: 'paid devinlytle' },
  { reg: /^kassandra$/i, promo: 'onemonthfreetrial', event: 'paid kassandra' },
  { reg: /^shallon$/i, promo: 'onemonthfreetrial', event: 'paid shallon' },
  { reg: /^carmen$/i, promo: 'onemonthfreetrial', event: 'paid carmen' },
  { reg: /^lizplank$/i, promo: 'onemonthfreetrial', event: 'paid lizplank' },
  { reg: /^married$/i, promo: 'onemonthfreetrial', event: 'podcast married' },
  { reg: /^guns$/i, promo: 'onemonthfreetrial', event: 'podcast guns' },
  { reg: /^everybody$/i, promo: 'onemonthfreetrial', event: 'podcast everybody' },
  { reg: /^travel$/i, promo: 'onemonthfreetrial', event: 'podcast travel' },
  { reg: /^sheer$/i, promo: 'onemonthfreetrial', event: 'podcast sheer' },
  { reg: /^postcard$/i, promo: 'onemonthfreetrial', event: 'podcast postcard' },
  { reg: /^daily$/i, promo: 'onemonthfreetrial', event: 'podcast daily' },
  { reg: /^mummies$/i, promo: 'onemonthfreetrial', event: 'podcast mummies' },
  { reg: /^walk$/i, promo: 'onemonthfreetrial', event: 'podcast walk' },
  { reg: /^judygold$/i, promo: 'onemonthfreetrial', event: 'podcast judygold' },
  { reg: /^dragrace$/i, promo: 'onemonthfreetrial', event: 'podcast dragrace' },
  { reg: /^funny$/i, promo: 'onemonthfreetrial', event: 'podcast funny' },
  { reg: /^straws$/i, promo: 'onemonthfreetrial', event: 'podcast straws' },
  { reg: /^health$/i, promo: 'onemonthfreetrial', event: 'podcast health' },
  { reg: /^fboy$/i, promo: 'onemonthfreetrial', event: 'podcast fboy' },
  { reg: /^sound$/i, promo: 'onemonthfreetrial', event: 'podcast sound' },
  { reg: /^taste$/i, promo: 'onemonthfreetrial', event: 'podcast taste' },
  { reg: /^toast$/i, promo: 'onemonthfreetrial', event: 'podcast toast' },
  { reg: /^milehigher$/i, promo: 'onemonthfreetrial', event: 'podcast milehigher' },
  { reg: /^humble$/i, promo: 'onemonthfreetrial', event: 'podcast humble' },
  { reg: /^inmyheart$/i, promo: 'onemonthfreetrial', event: 'podcast inmyheart' },
  { reg: /^absolutely$/i, promo: 'onemonthfreetrial', event: 'podcast absolutely' },
  { reg: /^bible$/i, promo: 'onemonthfreetrial', event: 'podcast bible' },
  { reg: /^dnd$/i, promo: 'onemonthfreetrial', event: 'podcast dnd' },
  { reg: /^ologies$/i, promo: 'onemonthfreetrial', event: 'podcast ologies' },
  { reg: /^pajama$/i, promo: 'onemonthfreetrial', event: 'podcast pajama' },
  { reg: /^wmma$/i, promo: 'onemonthfreetrial', event: 'podcast wmma' },
  { reg: /^edith$/i, promo: 'onemonthfreetrial', event: 'podcast edith' },
  { reg: /^itchy$/i, promo: 'onemonthfreetrial', event: 'podcast itchy' },
  { reg: /^sobervoices$/i, promo: 'onemonthfreetrial', event: 'partnership sobervoices' },
  { reg: /^kitty$/i, promo: 'onemonthfreetrial', event: 'partnership kitty' },
  { reg: /^thebuff$/i, promo: 'onemonthfreetrial', event: 'partnership thebuff' },
  { reg: /^erotic$/i, promo: 'onemonthfreetrial', event: 'podcast erotic' },
  { reg: /^noplace$/i, promo: 'onemonthfreetrial', event: 'podcast noplace' },
  { reg: /^thread$/i, promo: 'onemonthfreetrial', event: 'podcast thread' },
  { reg: /^spotify$/i, promo: 'onemonthfreetrial', event: 'partnership spotify' },
  { reg: /^jessiepaege$/i, promo: 'onemonthfreetrial', event: 'partnership jessiepaege' },
  { reg: /^givedipsea2021$/i, promo: '30percentoff', event: 'give dipsea 2021', isAvailableOnGift: true, subType: 'annual' },
  { reg: /^nosoul$/i, promo: 'onemonthfreetrial', event: 'podcast nosoul' },
  { reg: /^reality$/i, promo: 'onemonthfreetrial', event: 'podcast reality' },
  { reg: /^taylor$/i, promo: 'onemonthfreetrial', event: 'podcast taylor' },
  { reg: /^hslovesyou$/i, promo: 'onemonthfreetrial', event: 'partner hslovesyou' }, // intended to be live Dec 1, 2021 - Jan 31, 2022
  { reg: /^macy30$/i, promo: '30percentoff', event: 'inf macy30' },
  { reg: /^kenzie30$/i, promo: '30percentoff', event: 'inf kenzie30' },
  { reg: /^kenzie$/i, promo: 'onemonthfreetrial', event: 'inf kenzie' },
  { reg: /^stylelikeu$/i, promo: 'onemonthfreetrial', event: 'partnership stylelikeu' },
  { reg: /^90dayfiance$/i, promo: 'onemonthfreetrial', event: 'podcast 90dayfiance' },
  { reg: /^addtocart$/i, promo: 'onemonthfreetrial', event: 'podcast addtocart' },
  { reg: /^asme$/i, promo: 'onemonthfreetrial', event: 'podcast asme' },
  { reg: /^attitudes$/i, promo: 'onemonthfreetrial', event: 'podcast attitudes' },
  { reg: /^betchelor$/i, promo: 'onemonthfreetrial', event: 'podcast betchelor' },
  { reg: /^betches$/i, promo: 'onemonthfreetrial', event: 'podcast betches' },
  { reg: /^blocked$/i, promo: 'onemonthfreetrial', event: 'podcast blocked' },
  { reg: /^bookclub$/i, promo: 'onemonthfreetrial', event: 'podcast bookclub' },
  { reg: /^casket$/i, promo: 'onemonthfreetrial', event: 'podcast casket' },
  { reg: /^catfish$/i, promo: 'onemonthfreetrial', event: 'podcast catfish' },
  { reg: /^dragher$/i, promo: 'onemonthfreetrial', event: 'podcast dragher' },
  { reg: /^drunk$/i, promo: 'onemonthfreetrial', event: 'podcast drunk' },
  { reg: /^dst$/i, promo: 'onemonthfreetrial', event: 'podcast dst' },
  { reg: /^everybody$/i, promo: 'onemonthfreetrial', event: 'podcast everybody' },
  { reg: /^fboy$/i, promo: 'onemonthfreetrial', event: 'podcast fboy' },
  { reg: /^freethegirls$/i, promo: 'onemonthfreetrial', event: 'podcast freethegirls' },
  { reg: /^funny$/i, promo: 'onemonthfreetrial', event: 'podcast funny' },
  { reg: /^golden$/i, promo: 'onemonthfreetrial', event: 'podcast golden' },
  { reg: /^goodsex$/i, promo: 'onemonthfreetrial', event: 'podcast goodsex' },
  { reg: /^guns$/i, promo: 'onemonthfreetrial', event: 'podcast guns' },
  { reg: /^health$/i, promo: 'onemonthfreetrial', event: 'podcast health' },
  { reg: /^howto$/i, promo: 'onemonthfreetrial', event: 'podcast howto' },
  { reg: /^inrecovery$/i, promo: 'onemonthfreetrial', event: 'podcast inrecovery' },
  { reg: /^jaime$/i, promo: 'onemonthfreetrial', event: 'podcast jaime' },
  { reg: /^jinkx$/i, promo: 'onemonthfreetrial', event: 'podcast jinkx' },
  { reg: /^judgies$/i, promo: 'onemonthfreetrial', event: 'podcast judgies' },
  { reg: /^justbetweenus$/i, promo: 'onemonthfreetrial', event: 'podcast justbetweenus' },
  { reg: /^laid$/i, promo: 'onemonthfreetrial', event: 'podcast laid' },
  { reg: /^lastday$/i, promo: 'onemonthfreetrial', event: 'podcast lastday' },
  { reg: /^mention$/i, promo: 'onemonthfreetrial', event: 'podcast mention' },
  { reg: /^momanddad$/i, promo: 'onemonthfreetrial', event: 'podcast momanddad' },
  { reg: /^mood$/i, promo: 'onemonthfreetrial', event: 'podcast mood' },
  { reg: /^mummies$/i, promo: 'onemonthfreetrial', event: 'podcast mummies' },
  { reg: /^offtherecord$/i, promo: 'onemonthfreetrial', event: 'podcast offtherecord' },
  { reg: /^online$/i, promo: 'onemonthfreetrial', event: 'podcast online' },
  { reg: /^outfit$/i, promo: 'onemonthfreetrial', event: 'podcast outfit' },
  { reg: /^outward$/i, promo: 'onemonthfreetrial', event: 'podcast outward' },
  { reg: /^panic$/i, promo: 'onemonthfreetrial', event: 'podcast panic' },
  { reg: /^parts$/i, promo: 'onemonthfreetrial', event: 'podcast parts' },
  { reg: /^postcard$/i, promo: 'onemonthfreetrial', event: 'podcast postcard' },
  { reg: /^secret$/i, promo: 'onemonthfreetrial', event: 'podcast secret' },
  { reg: /^sloppy$/i, promo: 'onemonthfreetrial', event: 'podcast sloppy' },
  { reg: /^straws$/i, promo: 'onemonthfreetrial', event: 'podcast straws' },
  { reg: /^strictlyanon$/i, promo: 'onemonthfreetrial', event: 'podcast strictlyanon' },
  { reg: /^teencreeps$/i, promo: 'onemonthfreetrial', event: 'podcast teencreeps' },
  { reg: /^teenmom$/i, promo: 'onemonthfreetrial', event: 'podcast teenmom' },
  { reg: /^thechop$/i, promo: 'onemonthfreetrial', event: 'podcast thechop' },
  { reg: /^uup$/i, promo: 'onemonthfreetrial', event: 'podcast uup' },
  { reg: /^velvet$/i, promo: 'onemonthfreetrial', event: 'podcast velvet' },
  { reg: /^verythat$/i, promo: 'onemonthfreetrial', event: 'podcast verythat' },
  { reg: /^walk$/i, promo: 'onemonthfreetrial', event: 'podcast walk' },
  { reg: /^waves$/i, promo: 'onemonthfreetrial', event: 'podcast waves' },
  { reg: /^annie$/i, promo: 'onemonthfreetrial', event: 'podcast annie' },
  { reg: /^beach$/i, promo: 'onemonthfreetrial', event: 'podcast beach', welcomeBannerText: 'Welcome Back to the Beach podcast listeners!' },
  { reg: /^beauty$/i, promo: 'onemonthfreetrial', event: 'podcast beauty' },
  { reg: /^enjoy$/i, promo: 'onemonthfreetrial', event: 'podcast enjoy' },
  { reg: /^gmbc$/i, promo: 'onemonthfreetrial', event: 'podcast gmbc' },
  { reg: /^goingwest$/i, promo: 'onemonthfreetrial', event: 'podcast goingwest' },
  { reg: /^hardorsoft$/i, promo: 'onemonthfreetrial', event: 'podcast hardorsoft' },
  { reg: /^katecasey$/i, promo: 'onemonthfreetrial', event: 'podcast katecasey' },
  { reg: /^loved$/i, promo: 'onemonthfreetrial', event: 'podcast loved' },
  { reg: /^mysteries$/i, promo: 'onemonthfreetrial', event: 'podcast mysteries' },
  { reg: /^nutrition$/i, promo: 'onemonthfreetrial', event: 'podcast nutrition' },
  { reg: /^pop$/i, promo: 'onemonthfreetrial', event: 'podcast pop' },
  { reg: /^pstp$/i, promo: 'onemonthfreetrial', event: 'podcast pstp' },
  { reg: /^rich$/i, promo: 'onemonthfreetrial', event: 'podcast rich' },
  { reg: /^richdaily$/i, promo: 'onemonthfreetrial', event: 'podcast richdaily' },
  { reg: /^tapin$/i, promo: 'onemonthfreetrial', event: 'podcast tapin' },
  { reg: /^tcac$/i, promo: 'onemonthfreetrial', event: 'podcast tcac' },
  { reg: /^theroom$/i, promo: 'onemonthfreetrial', event: 'podcast theroom' },
  { reg: /^oloni$/i, promo: 'onemonthfreetrial', event: 'inf oloni' },
  { reg: /^hayley$/i, promo: 'onemonthfreetrial', event: 'inf hayley' },
  { reg: /^evyan$/i, promo: 'onemonthfreetrial', event: 'inf evyan' },
  { reg: /^dipseavday$/i, promo: '30percentoff', event: 'web dipseavday' },
  { reg: /^thirdlove$/i, promo: '30percentoff', event: 'podcast thirdlove' },
  { reg: /^vicky$/i, promo: 'onemonthfreetrial', event: 'podcast vicky' },
  { reg: /^cult$/i, promo: 'onemonthfreetrial', event: 'podcast cult', welcomeBannerText: 'Welcome Sounds Like a Cult listeners!' },
  { reg: /^deuxu$/i, promo: 'onemonthfreetrial', event: 'podcast deuxu' },
  { reg: /^drama$/i, promo: 'onemonthfreetrial', event: 'podcast drama' },
  { reg: /^nikki$/i, promo: 'onemonthfreetrial', event: 'podcast nikki' },
  { reg: /^awesome$/i, promo: 'onemonthfreetrial', event: 'podcast awesome' },
  { reg: /^bg$/i, promo: 'onemonthfreetrial', event: 'podcast bg' },
  { reg: /^darkheights$/i, promo: 'onemonthfreetrial', event: 'podcast darkheights' },
  { reg: /^ecochic$/i, promo: 'onemonthfreetrial', event: 'podcast ecochic' },
  { reg: /^elixir$/i, promo: 'onemonthfreetrial', event: 'podcast elixir' },
  { reg: /^exactly$/i, promo: 'onemonthfreetrial', event: 'podcast exactly' },
  { reg: /^isabel$/i, promo: 'onemonthfreetrial', event: 'podcast isabel' },
  { reg: /^drdanielle$/i, promo: 'onemonthfreetrial', event: 'podcast drdanielle' },
  { reg: /^sharp$/i, promo: 'onemonthfreetrial', event: 'podcast sharp' },
  { reg: /^thisisdating$/i, promo: 'onemonthfreetrial', event: 'podcast thisisdating' },
  { reg: /^momroom$/i, promo: 'onemonthfreetrial', event: 'podcast momroom' },
  { reg: /^hedonism$/i, promo: 'onemonthfreetrial', event: 'inf hedonism' },
  { reg: /^hedonism30$/i, promo: 'onemonthfreetrial', event: 'web hedonism30' },
  { reg: /^tuesday$/i, promo: 'onemonthfreetrial', event: 'podcast tuesday' },
  { reg: /^savage$/i, promo: 'onemonthfreetrial', event: 'podcast savage', welcomeBannerText: 'Welcome Savage Lovecast listeners!' },
  { reg: /^mlm$/i, promo: 'onemonthfreetrial', event: 'podcast mlm' },
  { reg: /^chelsea$/i, promo: 'onemonthfreetrial', event: 'podcast chelsea' },
  { reg: /^seventeen$/i, promo: 'onemonthfreetrial', event: 'podcast seventeen' },
  { reg: /^lukecook$/i, promo: 'onemonthfreetrial', event: 'lukecook promo' },
  { reg: /^safensexy$/i, promo: 'onemonthfreetrial', event: 'safensexy event' },
  { reg: /^reddit$/i, promo: 'onemonthfreetrial', event: 'reddit promo' },
  { reg: /^sanctuary$/i, promo: 'onemonthfreetrial', event: 'partner sanctuary' },
  { reg: /^kim$/i, promo: 'onemonthfreetrial', event: 'inf kim' },
  { reg: /^becca$/i, promo: 'onemonthfreetrial', event: 'inf becca' },
  { reg: /^eli$/i, promo: 'onemonthfreetrial', event: 'inf eli' },
  { reg: /^nadya$/i, promo: 'onemonthfreetrial', event: 'inf nadya' },
  { reg: /^bad$/i, promo: 'onemonthfreetrial', event: 'podcast bad' },
  { reg: /^baddecisions$/i, promo: 'onemonthfreetrial', event: 'podcast baddecisions' },
  { reg: /^chicks$/i, promo: 'onemonthfreetrial', event: 'podcast chicks' },
  { reg: /^datesandmates$/i, promo: 'onemonthfreetrial', event: 'podcast datesandmates' },
  { reg: /^deepdives$/i, promo: 'onemonthfreetrial', event: 'podcast deepdives' },
  { reg: /^fds$/i, promo: 'onemonthfreetrial', event: 'podcast fds' },
  { reg: /^glitter$/i, promo: 'onemonthfreetrial', event: 'podcast glitter' },
  { reg: /^hotmess$/i, promo: 'onemonthfreetrial', event: 'podcast hotmess' },
  { reg: /^kamie$/i, promo: 'onemonthfreetrial', event: 'podcast kamie' },
  { reg: /^notskinny$/i, promo: 'onemonthfreetrial', event: 'podcast notskinny' },
  { reg: /^overshare$/i, promo: 'onemonthfreetrial', event: 'podcast overshare' },
  { reg: /^padilla$/i, promo: 'onemonthfreetrial', event: 'podcast padilla' },
  { reg: /^pourminds$/i, promo: 'onemonthfreetrial', event: 'podcast pourminds' },
  { reg: /^rivalry$/i, promo: 'onemonthfreetrial', event: 'podcast rivalry' },
  { reg: /^shay$/i, promo: 'onemonthfreetrial', event: 'podcast shay' },
  { reg: /^tana$/i, promo: 'onemonthfreetrial', event: 'podcast tana' },
  { reg: /^unfazed$/i, promo: 'onemonthfreetrial', event: 'podcast unfazed' },
  { reg: /^vcg$/i, promo: 'onemonthfreetrial', event: 'podcast vcg', welcomeBannerText: 'Welcome Violating Community Guidelines listeners!' },
  { reg: /^viral$/i, promo: 'onemonthfreetrial', event: 'podcast viral' },
  { reg: /^wild$/i, promo: 'onemonthfreetrial', event: 'podcast wild' },
  { reg: /^unhinged$/i, promo: 'onemonthfreetrial', event: 'podcast unhinged' },
  { reg: /^spoiler$/i, promo: 'onemonthfreetrial', event: 'podcast spoiler' },
  { reg: /^scoggs$/i, promo: 'onemonthfreetrial', event: 'podcast scoggs' },
  { reg: /^hudson$/i, promo: 'onemonthfreetrial', event: 'podcast hudson' },
  { reg: /^ggriot$/i, promo: 'onemonthfreetrial', event: 'podcast ggriot' },
  { reg: /^ellie$/i, promo: 'onemonthfreetrial', event: 'podcast ellie' },
  { reg: /^famous$/i, promo: 'onemonthfreetrial', event: 'podcast famous' },
  { reg: /^worm$/i, promo: 'onemonthfreetrial', event: 'podcast worm', welcomeBannerText: `Welcome Celebrity Memoir Book Club listeners!` },
  { reg: /^ag$/i, promo: 'onemonthfreetrial', event: 'podcast ag' },
  { reg: /^erfightmaster$/i, promo: 'onemonthfreetrial', event: 'iterable erfightmaster' },
  { reg: /^ted$/i, promo: 'onemonthfreetrial', event: 'ted' },
  { reg: /^alayna$/i, promo: 'onemonthfreetrial', event: 'inf alayna' },
  { reg: /^courtandlex$/i, promo: 'onemonthfreetrial', event: 'inf courtandlex' },
  { reg: /^devrie$/i, promo: 'onemonthfreetrial', event: 'inf devrie' },
  { reg: /^violetclair$/i, promo: 'onemonthfreetrial', event: 'inf violetclair' },
  { reg: /^girlsafterdark$/i, promo: 'onemonthfreetrial', event: 'podcast girlsafterdark' },
  { reg: /^herspace$/i, promo: 'onemonthfreetrial', event: 'podcast herspace' },
  { reg: /^hunk-quiz-20$/i, promo: '20percentoff', event: 'hunk-quiz-20' },
  { reg: /^hunk-quiz-50$/i, promo: 'fiftypercentoff', event: 'hunk-quiz-50' },
  { reg: /^trope-quiz-20$/i, promo: '20percentoff', event: 'trope-quiz-20' },
  { reg: /^alan$/i, promo: 'onemonthfreetrial', event: 'podcast alan' },
  { reg: /^book$/i, promo: 'onemonthfreetrial', event: 'podcast book' },
  { reg: /^fool$/i, promo: 'onemonthfreetrial', event: 'podcast fool' },
  { reg: /^juicy$/i, promo: 'onemonthfreetrial', event: 'podcast juicy' },
  { reg: /^tco$/i, promo: 'onemonthfreetrial', event: 'podcast tco', welcomeBannerText: `Welcome True Crime Obsessed listeners!` },
  { reg: /^eli30$/i, promo: 'onemonthfreetrial', event: 'inf eli' },
  { reg: /^annamarie$/i, promo: 'onemonthfreetrial', event: 'podcast annamarie', welcomeBannerText: 'Welcome Annamarie Forcino viewers!' },
  { reg: /^arielle$/i, promo: 'onemonthfreetrial', event: 'podcast arielle' },
  { reg: /^bbb$/i, promo: 'onemonthfreetrial', event: 'podcast bbb' },
  { reg: /^doses$/i, promo: 'onemonthfreetrial', event: 'podcast doses' },
  { reg: /^ebony$/i, promo: 'onemonthfreetrial', event: 'podcast ebony' },
  { reg: /^gay$/i, promo: 'onemonthfreetrial', event: 'podcast gay', welcomeBannerText: `Welcome We're Having Gay Sex listeners!` },
  { reg: /^ijdhtf$/i, promo: 'onemonthfreetrial', event: 'podcast ijdhtf' },
  { reg: /^iloveyou$/i, promo: 'onemonthfreetrial', event: 'podcast iloveyou' },
  { reg: /^inthegrey$/i, promo: 'onemonthfreetrial', event: 'podcast inthegrey' },
  { reg: /^kimbyrleigha$/i, promo: 'onemonthfreetrial', event: 'podcast kimbyrleigha' },
  { reg: /^manifest$/i, promo: 'onemonthfreetrial', event: 'podcast manifest' },
  { reg: /^nicole$/i, promo: 'onemonthfreetrial', event: 'podcast nicole' },
  { reg: /^notetoself$/i, promo: 'onemonthfreetrial', event: 'podcast notetoself' },
  { reg: /^patdown$/i, promo: 'onemonthfreetrial', event: 'podcast patdown' },
  { reg: /^sherrilyn$/i, promo: 'onemonthfreetrial', event: 'podcast sherrilyn', welcomeBannerText: `Welcome Sherrilyn Dale viewers!` },
  { reg: /^sipslow$/i, promo: 'onemonthfreetrial', event: 'podcast sipslow' },
  { reg: /^slay$/i, promo: 'onemonthfreetrial', event: 'podcast slay' },
  { reg: /^talk$/i, promo: 'onemonthfreetrial', event: 'podcast talk' },
  { reg: /^theresa$/i, promo: 'onemonthfreetrial', event: 'podcast theresa' },
  { reg: /^think$/i, promo: 'onemonthfreetrial', event: 'podcast think' },
  { reg: /^trinity$/i, promo: 'onemonthfreetrial', event: 'podcast trinity' },
  { reg: /^unsolicited$/i, promo: 'onemonthfreetrial', event: 'podcast unsolicited' },
  { reg: /^qcode$/i, promo: 'onemonthfreetrial', event: 'podcast qcode' },
  { reg: /^narcissa$/i, promo: 'onemonthfreetrial', event: 'podcast narcissa', welcomeBannerText: 'Welcome Narcissa listeners!' },
  { reg: /^atwhs$/i, promo: 'onemonthfreetrial', event: 'podcast atwhs' },
  { reg: /^char$/i, promo: 'onemonthfreetrial', event: 'podcast char' },
  { reg: /^dailybeans$/i, promo: 'onemonthfreetrial', event: 'podcast dailybeans' },
  { reg: /^ffpod$/i, promo: 'onemonthfreetrial', event: 'podcast ffpod' },
  { reg: /^keara$/i, promo: 'onemonthfreetrial', event: 'podcast keara', welcomeBannerText: 'Welcome Keara Graves viewers!' },
  { reg: /^peyton$/i, promo: 'onemonthfreetrial', event: 'podcast peyton' },
  { reg: /^pillow$/i, promo: 'onemonthfreetrial', event: 'podcast pillow' },
  { reg: /^redhanded$/i, promo: 'onemonthfreetrial', event: 'podcast redhanded' },
  { reg: /^tht$/i, promo: 'onemonthfreetrial', event: 'podcast tht' },
  { reg: /^tu$/i, promo: 'onemonthfreetrial', event: 'podcast tu' },
  { reg: /^usalways$/i, promo: 'onemonthfreetrial', event: 'podcast usalways' },
  { reg: /^nessa$/i, promo: 'onemonthfreetrial', event: 'podcast nessa' },
  { reg: /^rowanellis$/i, promo: 'onemonthfreetrial', event: 'podcast rowanellis' },
  { reg: /^cultpod$/i, promo: 'onemonthfreetrial', event: 'podcast cultpod' },
  { reg: /^youaregood$/i, promo: 'onemonthfreetrial', event: 'podcast youaregood' },
  { reg: /^allusionist$/i, promo: 'onemonthfreetrial', event: 'podcast allusionist' },
  { reg: /^queermovie$/i, promo: 'onemonthfreetrial', event: 'podcast queermovie' },
  { reg: /^vampire$/i, promo: 'twoweeksfreetrial', event: 'Vampire Series Release' },
  { reg: /^sexualhealthevent$/i, promo: 'onemonthfreetrial', event: 'Foria Sexual Health Event' },
  { reg: /^vampire30$/i, promo: 'onemonthfreetrial', event: 'iterable vampire30' },
  { reg: /^extended14d$/i, promo: 'twoweeksfreetrial', event: 'Iterable extended14d' },
  { reg: /^signup30$/i, promo: '30percentoff', event: 'Iterable signup30' },
  { reg: /^dipsea30$/i, promo: '30percentoffNoTrial', event: 'Iterable dipsea30' },
  { reg: /^qcode$/i, promo: 'onemonthfreetrial', event: 'podcast qcode' },
  { reg: /^qcode$/i, promo: 'onemonthfreetrial', event: 'podcast qcode' },
  { reg: /^narcissa$/i, promo: 'onemonthfreetrial', event: 'podcast narcissa' },
  { reg: /^narcissa$/i, promo: 'onemonthfreetrial', event: 'podcast narcissa' },
  { reg: /^atwhs$/i, promo: 'onemonthfreetrial', event: 'podcast atwhs' },
  { reg: /^atwhs$/i, promo: 'onemonthfreetrial', event: 'podcast atwhs' },
  { reg: /^char$/i, promo: 'onemonthfreetrial', event: 'podcast char' },
  { reg: /^char$/i, promo: 'onemonthfreetrial', event: 'podcast char' },
  { reg: /^dailybeans$/i, promo: 'onemonthfreetrial', event: 'podcast dailybeans' },
  { reg: /^dailybeans$/i, promo: 'onemonthfreetrial', event: 'podcast dailybeans' },
  { reg: /^ffpod$/i, promo: 'onemonthfreetrial', event: 'podcast ffpod' },
  { reg: /^ffpod$/i, promo: 'onemonthfreetrial', event: 'podcast ffpod' },
  { reg: /^keara$/i, promo: 'onemonthfreetrial', event: 'podcast keara' },
  { reg: /^keara$/i, promo: 'onemonthfreetrial', event: 'podcast keara' },
  { reg: /^peyton$/i, promo: 'onemonthfreetrial', event: 'podcast peyton' },
  { reg: /^peyton$/i, promo: 'onemonthfreetrial', event: 'podcast peyton' },
  { reg: /^pillow$/i, promo: 'onemonthfreetrial', event: 'podcast pillow' },
  { reg: /^pillow$/i, promo: 'onemonthfreetrial', event: 'podcast pillow' },
  { reg: /^redhanded$/i, promo: 'onemonthfreetrial', event: 'podcast redhanded' },
  { reg: /^redhanded$/i, promo: 'onemonthfreetrial', event: 'podcast redhanded' },
  { reg: /^tht$/i, promo: 'onemonthfreetrial', event: 'podcast tht' },
  { reg: /^tht$/i, promo: 'onemonthfreetrial', event: 'podcast tht' },
  { reg: /^tu$/i, promo: 'onemonthfreetrial', event: 'podcast tu' },
  { reg: /^tu$/i, promo: 'onemonthfreetrial', event: 'podcast tu' },
  { reg: /^usalways$/i, promo: 'onemonthfreetrial', event: 'podcast usalways' },
  { reg: /^usalways$/i, promo: 'onemonthfreetrial', event: 'podcast usalways' },
  { reg: /^nessa$/i, promo: 'onemonthfreetrial', event: 'podcast nessa' },
  { reg: /^nessa$/i, promo: 'onemonthfreetrial', event: 'podcast nessa' },
  { reg: /^rowanellis$/i, promo: 'onemonthfreetrial', event: 'podcast rowanellis' },
  { reg: /^rowanellis$/i, promo: 'onemonthfreetrial', event: 'podcast rowanellis' },
  { reg: /^cultpod$/i, promo: 'onemonthfreetrial', event: 'podcast cultpod' },
  { reg: /^cultpod$/i, promo: 'onemonthfreetrial', event: 'podcast cultpod' },
  { reg: /^youaregood$/i, promo: 'onemonthfreetrial', event: 'podcast youaregood' },
  { reg: /^youaregood$/i, promo: 'onemonthfreetrial', event: 'podcast youaregood' },
  { reg: /^allusionist$/i, promo: 'onemonthfreetrial', event: 'podcast allusionist' },
  { reg: /^allusionist$/i, promo: 'onemonthfreetrial', event: 'podcast allusionist' },
  { reg: /^queermovie$/i, promo: 'onemonthfreetrial', event: 'podcast queermovie' },
  { reg: /^queermovie$/i, promo: 'onemonthfreetrial', event: 'podcast queermovie' },
  { reg: /^vampire30$/i, promo: 'onemonthfreetrial', event: 'iterable vampire30' },
  { reg: /^extended14d$/i, promo: 'twoweeksfreetrial', event: 'Iterable extended14d' },
  { reg: /^signup30$/i, promo: '30percentoff', event: 'Iterable signup30' },
  { reg: /^dipsea30$/i, promo: '30percentoff', event: 'Iterable dipsea30' },
  { reg: /^extended14d$/i, promo: 'twoweeksfreetrial', event: 'Iterable extended14d' },
  { reg: /^signup30$/i, promo: '30percentoff', event: 'Iterable signup30' },
  { reg: /^dipsea30$/i, promo: '30percentoff', event: 'Iterable dipsea30' },
  { reg: /^cocktales$/i, promo: 'onemonthfreetrial', event: 'podcast cocktales' },
  { reg: /^dtf$/i, promo: 'onemonthfreetrial', event: 'podcast dtf' },
  { reg: /^slut$/i, promo: 'onemonthfreetrial', event: 'podcast slut' },
  { reg: /^advice$/i, promo: 'onemonthfreetrial', event: 'podcast advice' },
  { reg: /^bald$/i, promo: 'onemonthfreetrial', event: 'podcast bald' },
  { reg: /^crimesalad$/i, promo: 'onemonthfreetrial', event: 'podcast crimesalad' },
  { reg: /^dateme$/i, promo: 'onemonthfreetrial', event: 'podcast dateme' },
  { reg: /^dive$/i, promo: 'onemonthfreetrial', event: 'podcast dive' },
  { reg: /^drink$/i, promo: 'onemonthfreetrial', event: 'podcast drink' },
  { reg: /^earplay$/i, promo: 'onemonthfreetrial', event: 'podcast earplay' },
  { reg: /^gorge$/i, promo: 'onemonthfreetrial', event: 'podcast gorge' },
  { reg: /^hoh$/i, promo: 'onemonthfreetrial', event: 'podcast hoh' },
  { reg: /^loveme$/i, promo: 'onemonthfreetrial', event: 'podcast loveme' },
  { reg: /^lovers$/i, promo: 'onemonthfreetrial', event: 'podcast lovers' },
  { reg: /^ltsi$/i, promo: 'onemonthfreetrial', event: 'podcast ltsi' },
  { reg: /^mental$/i, promo: 'onemonthfreetrial', event: 'podcast mental' },
  { reg: /^once$/i, promo: 'onemonthfreetrial', event: 'podcast once' },
  { reg: /^queens$/i, promo: 'onemonthfreetrial', event: 'podcast queens' },
  { reg: /^sleep$/i, promo: 'onemonthfreetrial', event: 'podcast sleep' },
  { reg: /^snooki$/i, promo: 'onemonthfreetrial', event: 'podcast snooki' },
  { reg: /^social$/i, promo: 'onemonthfreetrial', event: 'podcast social' },
  { reg: /^tangents$/i, promo: 'onemonthfreetrial', event: 'podcast tangents' },
  { reg: /^taylor$/i, promo: 'onemonthfreetrial', event: 'podcast taylor' },
  { reg: /^tntc$/i, promo: 'onemonthfreetrial', event: 'podcast tntc' },
  { reg: /^willcole$/i, promo: 'onemonthfreetrial', event: 'podcast willcole' },
  { reg: /^withwhit$/i, promo: 'onemonthfreetrial', event: 'podcast withwhit' },
  { reg: /^abelina$/i, promo: 'onemonthfreetrial', event: 'yt abelina' },
  { reg: /^chilib$/i, promo: 'onemonthfreetrial', event: 'yt chilib' },
  { reg: /^cid$/i, promo: 'onemonthfreetrial', event: 'yt cid' },
  { reg: /^hannah$/i, promo: 'onemonthfreetrial', event: 'yt hannah' },
  { reg: /^kelsey$/i, promo: 'onemonthfreetrial', event: 'yt kelsey' },
  { reg: /^madisyn$/i, promo: 'onemonthfreetrial', event: 'yt madisyn' },
  { reg: /^mickey$/i, promo: 'onemonthfreetrial', event: 'yt mickey' },
  { reg: /^ride$/i, promo: 'onemonthfreetrial', event: 'yt ride' },
  { reg: /^teenoir$/i, promo: 'onemonthfreetrial', event: 'yt teenoir' },
  { reg: /^uncarley$/i, promo: 'onemonthfreetrial', event: 'yt uncarley' },
  { reg: /^abby$/i, promo: 'onemonthfreetrial', event: 'yt abby' },
  { reg: /^colandari$/i, promo: 'onemonthfreetrial', event: 'yt colandari' },
  { reg: /^dorisxchi$/i, promo: 'onemonthfreetrial', event: 'yt dorisxchi' },
  { reg: /^mckenna$/i, promo: 'onemonthfreetrial', event: 'yt mckenna' },
  { reg: /^melreads$/i, promo: 'onemonthfreetrial', event: 'yt melreads' },
  { reg: /^merle$/i, promo: 'onemonthfreetrial', event: 'yt merle' },
  { reg: /^leatherlace30$/i, promo: 'onemonthfreetrial', event: 'leatherlace30' },
  { reg: /^courted$/i, promo: 'twoweeksfreetrial', event: 'courted' },
  { reg: /^anniewood$/i, promo: 'onemonthfreetrial', event: 'podcast anniewood' },
  { reg: /^jtrain$/i, promo: 'onemonthfreetrial', event: 'podcast jtrain' },
  { reg: /^realm$/i, promo: 'onemonthfreetrial', event: 'podcast realm' },
  { reg: /^secretkeepers$/i, promo: 'onemonthfreetrial', event: 'podcast secretkeepers' },
  { reg: /^ska$/i, promo: 'onemonthfreetrial', event: 'podcast ska' },
  { reg: /^momdad$/i, promo: 'onemonthfreetrial', event: 'podcast momdad' },
  { reg: /^sexweek$/i, promo: 'fiftypercentoff', event: 'sexweek' },
  { reg: /^soho$/i, promo: 'onemonthfreetrial', event: 'soho' },
  { reg: /^juliette30$/i, promo: 'onemonthfreetrial', event: 'juliette30' },
  { reg: /^courted30$/i, promo: 'onemonthfreetrial', event: 'email courted30' },
  { reg: /^jenhatmaker$/i, promo: 'onemonthfreetrial', event: 'podcast jenhatmaker' },
  { reg: /^impact$/i, promo: 'oneweekfreetrial', event: 'impact impact' },
  { reg: /^impact30$/i, promo: 'onemonthfreetrial', event: 'impact impact30' },
  { reg: /^muse$/i, promo: 'onemonthfreetrial', event: 'partnership, event on Mar 7, 2023' },
  { reg: /^extended30d$/i, promo: 'onemonthfreetrial', event: 'email  extended30d' },
  { reg: /^annual50$/i, promo: 'fiftypercentoff', event: 'email  annual50' },
  { reg: /^ladiescircle$/i, promo: 'onemonthfreetrial', event: 'evt ladiescircle' },
  { reg: /^blog$/i, promo: 'onemonthfreetrial', event: 'Iterable blog' },
  { reg: /^blog30$/i, promo: '30percentoff', event: 'Iterable blog 30percentoff' },
  { reg: /^tiktok$/i, promo: 'oneweekfreetrial', event: 'tiktok tiktok' },
  { reg: /^tiktok30$/i, promo: 'onemonthfreetrial', event: 'tiktok tiktok30' },
  { reg: /^instagram$/i, promo: 'oneweekfreetrial', event: 'ig instagram' },
  { reg: /^instagram30$/i, promo: 'onemonthfreetrial', event: 'ig instagram30' },
  { reg: /^fb$/i, promo: 'oneweekfreetrial', event: 'fb fb' },
  { reg: /^fb30$/i, promo: 'onemonthfreetrial', event: 'fb fb30' },
  { reg: /^signup30d$/i, promo: 'onemonthfreetrial', event: 'email  signup30d' },
  { reg: /^try30d$/i, promo: 'onemonthfreetrial', event: 'web try30d' },
  { reg: /^onboarding14$/i, promo: 'twoweeksfreetrial', event: 'email  onboarding14' },
  { reg: /^bywayofspaces$/i, promo: 'onemonthfreetrial', event: 'events  bywayofspaces' },
  { reg: /^forbidden14$/i, promo: 'twoweeksfreetrial', event: 'email forbidden14' },
  { reg: /^forbidden30$/i, promo: 'onemonthfreetrial', event: 'email forbidden30' },
  { reg: /^allthefeels$/i, promo: 'onemonthfreetrial', event: 'events allthefeels' },
  { reg: /^godsandmen$/i, promo: 'onemonthfreetrial', event: 'email godsandmen' },
  { reg: /^30offannual$/i, promo: '30percentoff', event: 'iterable 30% off' },
  { reg: /^fyff$/i, promo: 'onemonthfreetrial', event: 'podcasttour fyff' },
  { reg: /^lovepod$/i, promo: 'onemonthfreetrial', event: 'podcasttour lovepod' },
  { reg: /^kenna$/i, promo: 'onemonthfreetrial', event: 'content launch kenna', welcomeBannerText: 'Listen to @kenna_bethany now!', creatorDisplayName: '@kenna_bethany' },
  { reg: /^mairsyy$/i, promo: 'onemonthfreetrial', event: 'content launch mairsyy', welcomeBannerText: 'Listen to @mairsyy now!', creatorDisplayName: '@mairsyy' },
  { reg: /^rashad$/i, promo: 'onemonthfreetrial', event: 'content launch rashad', welcomeBannerText: 'Listen to @therashadexperience now!', creatorDisplayName: '@therashadexperience' },
  { reg: /^becky$/i, promo: 'onemonthfreetrial', event: 'content launch becky', welcomeBannerText: 'Listen to @beckymissal now!', creatorDisplayName: '@beckymissal' },
  { reg: /^amanda$/i, promo: 'onemonthfreetrial', event: 'content launch amanda', welcomeBannerText: 'Listen to @bananaamarie now!', creatorDisplayName: '@bananaamarie' },
  { reg: /^greg$/i, promo: 'onemonthfreetrial', event: 'content launch greg', welcomeBannerText: 'Listen to @gregrogstad now!', creatorDisplayName: '@gregrogstad' },
  { reg: /^josh$/i, promo: 'onemonthfreetrial', event: 'content launch josh', welcomeBannerText: 'Listen to @joshdreamin now!', creatorDisplayName: '@joshdreamin' },
  { reg: /^rhys$/i, promo: 'onemonthfreetrial', event: 'content launch rhys', welcomeBannerText: 'Listen to @rhyssential now!', creatorDisplayName: '@rhyssential' },
  { reg: /^25offannual$/i, promo: '25percentoff', event: 'iterable 25% off' },
  { reg: /^50offannual$/i, promo: 'fiftypercentoff', event: 'iterable 50% off' },
  { reg: /^tiktokcreator$/i, promo: 'onemonthfreetrial', event: 'content launch tiktokcreator' },
  { reg: /^closecompanions$/i, promo: 'onemonthfreetrial', event: 'email closecompanions' },
  { reg: /^tracelysette$/i, promo: 'onemonthfreetrial', event: 'content launch tracelysette' },
  { reg: /^keepdipsea$/i, promo: '3499-price', event: 'iterable keepdipsea 50% off' },
  { reg: /^kristen$/i, promo: 'onemonthfreetrial', event: 'podcast kristen' },
  { reg: /^realm$/i, promo: 'onemonthfreetrial', event: 'podcast realm' },
  { reg: /^stanreport$/i, promo: 'onemonthfreetrial', event: 'podcast stanreport' },
  { reg: /^thedirt$/i, promo: 'onemonthfreetrial', event: 'podcast thedirt' },
  { reg: /^pride2023$/i, promo: 'fiftypercentoff', event: 'pride 2023', isAvailableOnGift: true },
  { reg: /^pride2023-6$/i, promo: 'fiftypercentoff', event: 'pride 2023', isAvailableOnGift: true, subType: SUBSCRIPTION_TYPES.SIX_MONTHS },
  { reg: /^broski$/i, promo: 'onemonthfreetrial', event: 'podcast broski' },
  { reg: /^psych$/i, promo: 'onemonthfreetrial', event: 'podcasttour psych' },
  { reg: /^lifeblood$/i, promo: 'onemonthfreetrial', event: 'podcasttour lifeblood' },
  { reg: /^positive$/i, promo: 'onemonthfreetrial', event: 'podcasttour positive' },
  { reg: /^egram$/i, promo: 'onemonthfreetrial', event: 'podcasttour egram' },
  { reg: /^bedbible$/i, promo: 'onemonthfreetrial', event: 'impact bedbible' },
  { reg: /^sts$/i, promo: 'onemonthfreetrial', event: 'podcasttour sts' },
  { reg: /^psych20$/i, promo: 'onemonthfreetrial', event: 'podcasttour psych20' },
  { reg: /^justbreakup$/i, promo: 'onemonthfreetrial', event: 'podcast justbreakup' },
  { reg: /^sahara$/i, promo: 'onemonthfreetrial', event: 'podcast sahara' },
  { reg: /^skinnydipping$/i, promo: 'onemonthfreetrial', event: 'podcast skinnydipping' },
  { reg: /^cameron$/i, promo: 'onemonthfreetrial', event: 'podcast cameron' },
  { reg: /^toast$/i, promo: 'onemonthfreetrial', event: 'podcast toast' },
  { reg: /^gabby$/i, promo: 'onemonthfreetrial', event: 'podcast gabby' },
  { reg: /^pretty$/i, promo: 'onemonthfreetrial', event: 'podcast pretty' },
  { reg: /^neardeath$/i, promo: 'onemonthfreetrial', event: 'podcast neardeath' },
  { reg: /^threads$/i, promo: 'oneweekfreetrial', event: 'threads' },
  { reg: /^threads30$/i, promo: 'onemonthfreetrial', event: 'threads' },
  { reg: /^threads25$/i, promo: '25percentoff', event: 'threads' },
  { reg: /^ao3$/i, promo: '25percentoff', event: 'tiktok ao3' },
  { reg: /^emma$/i, promo: 'onemonthfreetrial', event: 'podcasttour emma' },
  { reg: /^google30$/i, promo: 'onemonthfreetrial', event: 'cpc google30' },
  { reg: /^getnaked$/i, promo: 'onemonthfreetrial', event: 'podcast getnaked' },
  { reg: /^foreplay$/i, promo: 'onemonthfreetrial', event: 'podcast foreplay' },
  { reg: /^mtr$/i, promo: 'onemonthfreetrial', event: 'podcast mtr' },
  { reg: /^miss30$/i, promo: 'onemonthfreetrial', event: 'podcasttour miss30' },
  { reg: /^wingsofwinter$/i, promo: 'onemonthfreetrial', event: 'wings of winter promo' },
  { reg: /^fantasy-quiz-20$/i, promo: '20percentoff', event: 'fantasy-quiz-20' },
  { reg: /^afflicted$/i, promo: 'onemonthfreetrial', event: 'podcast afflicted' },
  { reg: /^bothered$/i, promo: 'onemonthfreetrial', event: 'podcast bothered' },
  { reg: /^dewme$/i, promo: 'onemonthfreetrial', event: 'podcast dewme' },
  { reg: /^ggriot$/i, promo: 'onemonthfreetrial', event: 'podcast ggriot' },
  { reg: /^medieval$/i, promo: 'onemonthfreetrial', event: 'podcast medieval' },
  { reg: /^sheets$/i, promo: 'onemonthfreetrial', event: 'podcast sheets' },
  { reg: /^syrup$/i, promo: 'onemonthfreetrial', event: 'podcast syrup' },
  { reg: /^tdd$/i, promo: 'onemonthfreetrial', event: 'podcast tdd' },
  { reg: /^rachel$/i, promo: 'onemonthfreetrial', event: 'podcasttour rachel' },
  { reg: /^corn$/i, promo: 'onemonthfreetrial', event: 'podcast corn' },
  { reg: /^pinedalefalls$/i, promo: 'onemonthfreetrial', event: 'pinedalefalls' },
  { reg: /^goddess14$/i, promo: 'twoweeksfreetrial', event: 'goddess14' },
  { reg: /^ella$/i, promo: 'onemonthfreetrial', event: 'podcasttour ella' },
  { reg: /^theknot$/i, promo: 'onemonthfreetrial', event: 'partner theknot' },
  { reg: /^brokegirl$/i, promo: 'onemonthfreetrial', event: 'podcast brokegirl' },
  { reg: /^cooper$/i, promo: 'onemonthfreetrial', event: 'podcast cooper' },
  { reg: /^dykingout$/i, promo: 'onemonthfreetrial', event: 'podcast dykingout' },
  { reg: /^haunted$/i, promo: 'onemonthfreetrial', event: 'podcast haunted' },
  { reg: /^lezhangout$/i, promo: 'onemonthfreetrial', event: 'podcast lezhangout' },
  { reg: /^megananne$/i, promo: 'onemonthfreetrial', event: 'podcast megananne' },
  { reg: /^oneroom$/i, promo: 'onemonthfreetrial', event: 'podcast oneroom' },
  { reg: /^vulgar$/i, promo: 'onemonthfreetrial', event: 'podcast vulgar' },
  { reg: /^spicyaudio$/i, promo: 'onemonthfreetrial', event: 'facebook spicyaudio' },
  { reg: /^useheadphones$/i, promo: '20percentoff', event: 'facebook useheadphones' },
  { reg: /^megananne$/i, promo: 'onemonthfreetrial', event: 'podcast megananne' },
  { reg: /^understood$/i, promo: 'onemonthfreetrial', event: 'podcast understood' },
  { reg: /^cooper$/i, promo: 'onemonthfreetrial', event: 'podcast cooper' },
  { reg: /^sexloveyoga$/i, promo: 'onemonthfreetrial', event: 'podcasttour sexloveyoga' },
  { reg: /^littletreat$/i, promo: 'fiftypercentoff', event: 'littletreat' },
  { reg: /^cindy$/i, promo: 'onemonthfreetrial', event: 'podcasttour cindy' },
  { reg: /^fictionalmen$/i, promo: 'onemonthfreetrial', event: 'dipseapodcast fictionalmen' },
  { reg: /^sapphic$/i, promo: 'onemonthfreetrial', event: 'dipseapodcast sapphic' },
  { reg: /^romantasy$/i, promo: 'onemonthfreetrial', event: 'dipseapodcast romantasy' },
  { reg: /^moan$/i, promo: 'onemonthfreetrial', event: 'dipseapodcast moan' },
  { reg: /^trybabies$/i, promo: 'onemonthfreetrial', event: 'podcast trybabies' },
  { reg: /^viall$/i, promo: 'onemonthfreetrial', event: 'podcast viall' },
  { reg: /^abby$/i, promo: 'onemonthfreetrial', event: 'podcast abby' },
  { reg: /^unfiltered$/i, promo: 'onemonthfreetrial', event: 'podcast unfiltered' },
  { reg: /^darkhistory$/i, promo: 'onemonthfreetrial', event: 'podcast darkhistory' },
  { reg: /^fks$/i, promo: 'onemonthfreetrial', event: 'podcast fks' },
  { reg: /^tcs$/i, promo: 'onemonthfreetrial', event: 'podcast tcs' },
  { reg: /^cotc$/i, promo: 'onemonthfreetrial', event: 'podcast cotc' },
  { reg: /^friend$/i, promo: 'onemonthfreetrial', event: 'podcast friend' },
  { reg: /^fluently$/i, promo: 'onemonthfreetrial', event: 'podcast fluently' },
  { reg: /^sistas$/i, promo: 'onemonthfreetrial', event: 'podcast sistas' },
  { reg: /^diaries$/i, promo: 'onemonthfreetrial', event: 'podcast diaries' },
  { reg: /^shespeaks$/i, promo: 'onemonthfreetrial', event: 'podcast shespeaks' },
  { reg: /^blinds$/i, promo: 'onemonthfreetrial', event: 'podcast blinds' },
  { reg: /^nightlight$/i, promo: 'onemonthfreetrial', event: 'podcast nightlight' },
  { reg: /^cthulhu$/i, promo: 'onemonthfreetrial', event: 'podcast cthulhu' },
  { reg: /^unwell$/i, promo: 'onemonthfreetrial', event: 'podcast unwell' },
  { reg: /^terrible$/i, promo: 'onemonthfreetrial', event: 'podcast terrible' },
  { reg: /^igtbo$/i, promo: 'onemonthfreetrial', event: 'podcast igtbo' },
  { reg: /^ttfa$/i, promo: 'onemonthfreetrial', event: 'podcast ttfa' },
  { reg: /^unschool$/i, promo: 'onemonthfreetrial', event: 'podcasttour unschool' },
  { reg: /^moonshadowe$/i, promo: 'twoweeksfreetrial', event: 'moonshadowe launch' },
  { reg: /^vday50$/i, promo: 'fiftypercentoff', event: '2024 vday promo' },
  { reg: /^spicynews$/i, promo: 'onemonthfreetrial', event: 'jerrynews spicynews' },
  { reg: /^disrespectfully$/i, promo: 'onemonthfreetrial', event: 'podcast disrespectfully' },
  { reg: /^heretofindlove$/i, promo: 'onemonthfreetrial', event: 'podcast heretofindlove' },
  { reg: /^romanceaudio$/i, promo: 'onemonthfreetrial', event: 'facebook romanceaudio' },
  { reg: /^spicyasmr$/i, promo: 'onemonthfreetrial', event: 'dipseapodcast spicyasmr' },
  { reg: /^nyu$/i, promo: 'onemonthfreetrial', event: 'dipseapodcast nyu' },
  { reg: /^meryl$/i, promo: 'onemonthfreetrial', event: 'romance author' },
  { reg: /^thedetour$/i, promo: 'onemonthfreetrial', event: 'the detour launch' },
  { reg: /^tht$/i, promo: 'onemonthfreetrial', event: 'podcast tht' },
  { reg: /^ae$/i, promo: 'onemonthfreetrial', event: 'podcast ae' },
  { reg: /^skinny$/i, promo: 'onemonthfreetrial', event: 'podcast skinny' },
  { reg: /^basic$/i, promo: 'onemonthfreetrial', event: 'podcast basic' },
  { reg: /^makeup$/i, promo: 'onemonthfreetrial', event: 'podcast makeup' },
  { reg: /^ttfa$/i, promo: 'onemonthfreetrial', event: 'podcast ttfa' },
  { reg: /^prettybasic$/i, promo: 'onemonthfreetrial', event: 'podcast prettybasic' },
  { reg: /^intercom$/i, promo: 'onemonthfreetrial', event: 'podcast intercom' },
  { reg: /^nextlevel$/i, promo: 'onemonthfreetrial', event: 'podcast nextlevel' },
  { reg: /^dateme$/i, promo: 'onemonthfreetrial', event: 'podcast dateme' },
  { reg: /^nightowl$/i, promo: 'onemonthfreetrial', event: 'podcast nightowl' },
  { reg: /^bern$/i, promo: 'onemonthfreetrial', event: 'podcast bern' },
  { reg: /^nlr$/i, promo: 'onemonthfreetrial', event: 'podcast nlr' },
  { reg: /^steph$/i, promo: 'onemonthfreetrial', event: 'podcast steph' },
  { reg: /^lachlan$/i, promo: 'onemonthfreetrial', event: 'dipsea promotion' },
  { reg: /^summersale30$/i, promo: '30percentoffNoTrial', event: 'facebook summersale30' },
  { reg: /^summersale50$/i, promo: 'fiftypercentoffNoTrial', event: 'facebook summersale50' },
  { reg: /^girltherapy$/i, promo: 'fiftypercentoffNoTrial', event: 'girltherapy' },
  { reg: /^bitesized$/i, promo: '25percentoff', event: 'dipseapodcast bitesized' },
  { reg: /^romantasy25$/i, promo: '25percentoff', event: 'dipseapodcast romantasy25' },
  { reg: /^sapphic25$/i, promo: '25percentoff', event: 'dipseapodcast sapphic25' },
  { reg: /^spicyasmr25$/i, promo: '25percentoff', event: 'dipseapodcast spicyasmr25' },
  { reg: /^smartsx$/i, promo: '25percentoff+onemonthfreetrial', event: 'smartsx' },
  { reg: /^avalon$/i, promo: 'onemonthfreetrial', event: 'avalon academy' },
  { reg: /^bitesized$/i, promo: '25percentoff', event: 'dipseapodcast bitesized' },
  { reg: /^romantasy25$/i, promo: '25percentoff', event: 'dipseapodcast romantasy25' },
  { reg: /^sapphic25$/i, promo: '25percentoff', event: 'dipseapodcast sapphic25' },
  { reg: /^spicyasmr25$/i, promo: '25percentoff', event: 'dipseapodcast spicyasmr25' },
  { reg: /^fictionalmen25$/i, promo: '25percentoff', event: 'dipseapodcast fictionalmen25' },
  { reg: /^logan$/i, promo: 'onemonthfreetrial', event: 'logan email' },
  { reg: /^firstkiss30$/i, promo: '3499-price-30-day-trial', event: '34.99' },
  { reg: /^slowburn30$/i, promo: '4499-price-30-day-trial', event: '44.99' },
  { reg: /^firstkiss$/i, promo: '3499-price', event: '34.99' },
  { reg: /^slowburn$/i, promo: '4499-price', event: '44.99' },
  { reg: /^try34$/i, promo: '3499-price', event: '34.99' },
  { reg: /^tryfree34$/i, promo: '3499-price-30-day-trial', event: '34.99' },
  { reg: /^tryfree47$/i, promo: '4799-price-30-day-trial', event: '47.99' },
  { reg: /^try44$/i, promo: '4499', event: '44.99' },
  { reg: /^tryfree44$/i, promo: '4499-price-30-day-trial', event: '44.99' },
  { reg: /^offthecoast1$/i, promo: '3499-price', event: '34.99' },
  { reg: /^pinedale34$/i, promo: '3499-price', event: '34.99' },
  { reg: /^carmilla34$/i, promo: '3499-price', event: '34.99' },
  { reg: /^preview34$/i, promo: '3499-price', event: '34.99' },
  { reg: /^preview30$/i, promo: 'onemonthfreetrial', event: '34.99' },
  { reg: /^niamh$/i, promo: '3499-price-30-day-trial', event: '34.99' },
  { reg: /^carmilla34$/i, promo: '3499-price', event: '34.99' },
  { reg: /^hometown34$/i, promo: '3499-price', event: '34.99' },
  { reg: /^advice$/i, promo: 'onemonthfreetrial', event: 'podcast advice' },
  { reg: /^sage$/i, promo: 'onemonthfreetrial', event: 'email' },
  { reg: /^xavier$/i, promo: 'onemonthfreetrial', event: 'email' },
  { reg: /^killian$/i, promo: 'onemonthfreetrial', event: 'iterable' },
  { reg: /^babygirl$/i, promo: 'oneweektrial', event: 'babygirl' },
  { reg: /^baskinsuns$/i, promo: '4499-price-30-day-trial', event: 'influencercommunity baskinsuns' },
  { reg: /^novelniah$/i, promo: '4499-price-30-day-trial', event: 'influencercommunity novelniah' },
  { reg: /^unhingedbookbitch$/i, promo: '4499-price-30-day-trial', event: 'influencercommunity unhingedbookbitch' },
  { reg: /^theehottgirlbooks$/i, promo: '4499-price-30-day-trial', event: 'influencercommunity theehottgirlbooks' },
  { reg: /^pam$/i, promo: '4499-price-30-day-trial', event: 'influencercommunity pam' },
  { reg: /^nina$/i, promo: '4499-price-30-day-trial', event: 'influencercommunity nina' },
  { reg: /^lexi$/i, promo: '4499-price-30-day-trial', event: 'influencercommunity lexi' },
  { reg: /^mandysreads$/i, promo: '4499-price-30-day-trial', event: 'influencercommunity mandysreads' },
  { reg: /^victoriaslistening$/i, promo: '4499-price-30-day-trial', event: 'influencercommunity victoriaslistening' },
  { reg: /^cozywithmia$/i, promo: '4499-price-30-day-trial', event: 'influencercommunity cozywithmia' },
  { reg: /^tritney$/i, promo: '4499-price-30-day-trial', event: 'influencercommunity tritney' },
  { reg: /^gabby$/i, promo: '4499-price-30-day-trial', event: 'influencercommunity gabby' },
  { reg: /^justpeachyreads$/i, promo: '4499-price-30-day-trial', event: 'influencercommunity justpeachyreads ' },
  { reg: /^try7d$/i, promo: 'oneweekfreetrial', event: 'try7d' },
  { reg: /^friendstolovers$/i, promo: 'oneweekfreetrial', event: 'try7d' },
  { reg: /^mrdarcy$/i, promo: 'oneweekfreetrial', event: 'try7d' },
  { reg: /^scared$/i, promo: 'onemonthfreetrial', event: 'podcast scared' },
  { reg: /^chilling$/i, promo: 'onemonthfreetrial', event: 'podcast chilling' },
  { reg: /^sloan$/i, promo: 'onemonthfreetrial', event: 'podcast sloan' },
  { reg: /^juliette$/i, promo: 'oneweekfreetrial', event: 'iterable' },
  { reg: /^james$/i, promo: 'oneweekfreetrial', event: 'iterable' },
  { reg: /^humblebrag$/i, promo: 'onemonthfreetrial', event: 'podcast humblebrag' },
  { reg: /^dating$/i, promo: 'onemonthfreetrial', event: 'podcast dating' },
  { reg: /^datedateline$/i, promo: 'onemonthfreetrial', event: 'podcast datedateline' },
  { reg: /^tcfc$/i, promo: 'onemonthfreetrial', event: 'podcast tcfc' },
  { reg: /^girlgone$/i, promo: 'onemonthfreetrial', event: 'podcast girlgone' },
  { reg: /^booktalk$/i, promo: 'onemonthfreetrial', event: 'podcast booktalk' },
  { reg: /^shespeaks$/i, promo: 'onemonthfreetrial', event: 'podcast shespeaks' },
  { reg: /^talia$/i, promo: 'onemonthfreetrial', event: 'podcast talia' },
  { reg: /^casefile$/i, promo: 'onemonthfreetrial', event: 'podcast casefile' },
  { reg: /^nightmare$/i, promo: 'onemonthfreetrial', event: 'podcast nightmare' },
  { reg: /^scorned$/i, promo: 'onemonthfreetrial', event: 'podcast scorned' },
  { reg: /^dateme$/i, promo: 'onemonthfreetrial', event: 'podcast dateme' },
  { reg: /^inhuman$/i, promo: 'onemonthfreetrial', event: 'podcast inhuman' },
  { reg: /^tooscary$/i, promo: 'onemonthfreetrial', event: 'podcast tooscary' },
  { reg: /^coldcase$/i, promo: 'onemonthfreetrial', event: 'podcast coldcase' },
  { reg: /^inacult$/i, promo: 'onemonthfreetrial', event: 'podcast inacult' },
  { reg: /^vibin$/i, promo: 'onemonthfreetrial', event: 'podcast vibin' },
  { reg: /^betrayal$/i, promo: 'onemonthfreetrial', event: 'podcast betrayal' },
  { reg: /^nancy$/i, promo: 'onemonthfreetrial', event: 'podcast nancy' },
  { reg: /^terreeannreads$/i, promo: '4499-price-30-day-trial', event: 'influencercommunity terreeannreads' },
  { reg: /^hadeelthereader $/i, promo: '4499-price-30-day-trial', event: 'influencercommunity hadeelthereader ' },
  { reg: /^haleyjakobson$/i, promo: '4499-price-30-day-trial', event: 'influencercommunity haleyjakobson' },
  { reg: /^azantareads$/i, promo: '4499-price-30-day-trial', event: 'influencercommunity azantareads' },
  { reg: /^minareads$/i, promo: '4499-price-30-day-trial', event: 'influencercommunity minareads' },
  { reg: /^gone$/i, promo: 'onemonthfreetrial', event: 'podcast gone' },
  { reg: /^homocide$/i, promo: 'onemonthfreetrial', event: 'podcast homocide' },
  { reg: /^artemis$/i, promo: 'onemonthfreetrial', event: 'artemis promo' },
  { reg: /^bemine$/i, promo: 'fiftypercentoffNoTrial', event: 'bemine promo' },
  { reg: /^vday50$/i, promo: 'fiftypercentoffNoTrial', event: 'vday promo' },
  { reg: /^sapphic50$/i, promo: '3499-price-7-day-trial', event: 'dipseapodcast sapphic50', welcomeBannerText: 'This Valentine’s Day, celebrate with our best deal ever.' },
  { reg: /^spicyasmr50$/i, promo: '3499-price-7-day-trial', event: 'dipseapodcast SPICYASMR50', welcomeBannerText: 'This Valentine’s Day, celebrate with our best deal ever.' },
  { reg: /^fictionalmen50$/i, promo: '3499-price-7-day-trial', event: 'dipseapodcast FICTIONALMEN50', welcomeBannerText: 'This Valentine’s Day, celebrate with our best deal ever.' },
  { reg: /^romantasy50$/i, promo: '3499-price-7-day-trial', event: 'dipseapodcast ROMANTASY50', welcomeBannerText: 'This Valentine’s Day, celebrate with our best deal ever.' },
  { reg: /^expansive$/i, promo: '25percentoff+onemonthfreetrial', event: 'the expansive group' },
  { reg: /^febflashsale$/i, promo: '30percentoffNoTrial', event: 'feb flashsale' },
  { reg: /^thehunt$/i, promo: 'oneweektrial', event: 'the hunt' },
  { reg: /^isa$/i, promo: 'oneweektrial', event: 'raise the bar' },
  { reg: /^getlucky$/i, promo: '30percentoff', event: 'get lucky' },
  { reg: /^aydsayso$/i, promo: '4499-price-30-day-trial', event: 'influencercommunity aydsayso' },
  { reg: /^carolineohno$/i, promo: '4499-price-30-day-trial', event: 'influencercommunity carolineohno' },
  { reg: /^sterlingsapphicreads$/i, promo: '4499-price-30-day-trial', event: 'influencercommunity sterlingsapphicreads' },
  { reg: /^bookishaddiction$/i, promo: '4499-price-30-day-trial', event: 'influencercommunity bookishaddiction' },
  { reg: /^naughtylibrarian$/i, promo: '4499-price-30-day-trial', event: 'influencercommunity naughtylibrarian' },
  { reg: /^alyxang$/i, promo: '4499-price-30-day-trial', event: 'influencercommunity alyxang' },
  { reg: /^herica$/i, promo: '4499-price-30-day-trial', event: 'influencercommunity herica' },
  { reg: /^mandyrulez$/i, promo: '4499-price-30-day-trial', event: 'influencercommunity mandyrulez' },
  { reg: /^marchsale$/i, promo: '50percentoffforever+oneweekfreetrial', event: 'march sale' },
  { reg: /^estherperel50$/i, promo: '50percentoffforever+oneweekfreetrial', event: 'evt estherperel50' },
  { reg: /^sessionslive50$/i, promo: '50percentoffforever+oneweekfreetrial', event: 'evt sessionslive50' },
  { reg: /^roses$/i, promo: '25percentoff+onemonthfreetrial', event: 'podcast roses' },
  { reg: /^catnat$/i, promo: '25percentoff+onemonthfreetrial', event: 'podcast catnat' },
  { reg: /^gwf$/i, promo: '25percentoff+onemonthfreetrial', event: 'podcast gwf' },
  { reg: /^mean$/i, promo: '25percentoff+onemonthfreetrial', event: 'podcast mean' },
  { reg: /^twoparents$/i, promo: '25percentoff+onemonthfreetrial', event: 'podcast twoparents' },
  { reg: /^balanced$/i, promo: '25percentoff+onemonthfreetrial', event: 'podcast balanced' },
  { reg: /^honest$/i, promo: '25percentoff+onemonthfreetrial', event: 'podcast honest' },
  { reg: /^gabbywindey$/i, promo: '25percentoff+onemonthfreetrial', event: 'podcast gabbywindey' },
  { reg: /^probably$/i, promo: '25percentoff+onemonthfreetrial', event: 'podcast probably' },
  { reg: /^swe$/i, promo: '25percentoff+onemonthfreetrial', event: 'podcast swe' },
  { reg: /^realityhits$/i, promo: '25percentoff+onemonthfreetrial', event: 'podcast realityhits' },
  { reg: /^brokegirl$/i, promo: '25percentoff+onemonthfreetrial', event: 'podcast brokegirl' },
  { reg: /^cum$/i, promo: '25percentoff+onemonthfreetrial', event: 'podcast cum' },
  { reg: /^juicyscoop$/i, promo: '25percentoff+onemonthfreetrial', event: 'podcast juicyscoop' },
  { reg: /^made$/i, promo: '25percentoff+onemonthfreetrial', event: 'podcast made' },
  { reg: /^snapped$/i, promo: '25percentoff+onemonthfreetrial', event: 'podcast snapped' },
  { reg: /^friendsandfamily$/i, promo: '50percentoffforever+oneweekfreetrial', event: 'friends and family sale' },
  { reg: /^estherletters$/i, promo: '25percentoff+oneweekfreetrial', event: 'esthers letter' }

]

type MatchPromoType = {
  isValid: boolean,
  errorMessage?: string,
  reg?: any,
  promo?: ?string,
  event?: string,
  welcomeBannerText?: ?string,
  header?: string,
  subHeader?: string,
  subType?: 'annual' | 'monthly' | 'six_months',
  isGiftAvailable?: boolean,
  creatorDisplayName?: string
}

export function matchPromos (promo: string, selectedPlan?: SubscriptionLengthType, isGift: boolean = false, featureAnnualOnly: boolean = false): MatchPromoType {
  if (promo && selectedPlan) {
    const match = PROMO_KEYS.filter((item: PromoType): ?string[] => {
      return promo.match(item.reg)
    })

    if ((!match || !match[0])) {
      return {
        isValid: false,
        errorMessage: `Could not find promo code ${(promo || '').toUpperCase()}.${isGift ? '' : ' Please refresh and try again.'}`
      }
    } else if (isGift && !match[0].isAvailableOnGift) {
      return {
        isValid: false,
        errorMessage: `Promo code not available for gifts`
      }
    }
    const promoItem = match[0]

    if (!promoItem.subType) {
      promoItem.subType = 'annual'
    }

    if (isGift && match[0].isAvailableOnGift) {
      if (promoItem.subType === selectedPlan) {
        return {
          ...promoItem,
          isValid: true
        }
      } else {
        return {
          ...promoItem,
          isValid: false,
          errorMessage: `Enter a valid promo code`
        }
      }
    } else if (promoItem && promoItem.subType && selectedPlan && !isGift) {
      if (promoItem.subType === selectedPlan) {
        return {
          ...promoItem,
          isValid: true
        }
      } else if (featureAnnualOnly && promoItem.subType === 'monthly') {
        return {
          isValid: false,
          errorMessage: `Promo code ${(promo || '').toUpperCase()} is not valid.`
        }
      } else {
        return {
          ...promoItem,
          isValid: false,
          errorMessage: `Promo code ${(promo || '').toUpperCase()} only valid for ${promoItem.subType ? promoItem.subType : 'annual'} subscriptions. Please choose the ${promoItem.subType ? promoItem.subType : 'annual'} plan.`
        }
      }
    } else {
      return {
        isValid: false,
        errorMessage: `Promo code ${(promo || '').toUpperCase()} only valid for ${SUBSCRIPTION_TYPES.ANNUAL} subscriptions. Please choose the ${SUBSCRIPTION_TYPES.ANNUAL} plan.`
      }
    }
  } else {
    return {
      isValid: false,
      errorMessage: `Could not find promo code. Please refresh and try again.`
    }
  }
}
